import React from "react";
import logo_maaia from "../../assets/images/maaia-logo.png";
import aiButtonicon from "../../assets/images/icons8-bot-48.png";
const RetoursClients = (props) => {
  const data = [
    {
      text: "De nombreux clients ont demandé les délais de gravure, il pourrait être intéressant de l’indiquer sur le site",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
    {
      text: "Il y a eu 4% d’emails en langues étrangeres cette semaine. cela indique que votre marque fait parler à l’international 👍",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
    {
      text: "3 clients allemand sont venu demandé les délais de livraison (les délais pour l’international ne sont pas indiqués survotre site).",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
    {
      text: "Mettre en place une bonne séquence de mails de notification pourrait réduire potentiellement de 20% les demandes sur le tag commande...",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
    {
      text: "1 personne souhaite porter plainte, vous devriez la contacter par téléphone. voici le lien vers l’email en question : lien",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
    {
      text: "3 clients allemand sont venu demandé les délais de livraison (les délais pour l’international ne sont pas indiqués sur votre site).",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
    {
      text: "Il y a eu 4% d’emails en langues étrangeres cette semaine. cela indique que votre marque fait parler à l’international 👍",
      iconClass: "mdi mdi-chat-alert dashboard-clients-icon",
    },
  ];
  const svgs = {
    slider_left: (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M10.0177 13.6083C10.5123 13.4399 10.6781 13.053 10.719 12.5731C10.747 12.2459 10.7363 11.9186 10.7363 11.5923C10.7381 8.18181 10.7381 4.77129 10.7363 1.36076C10.7363 1.10067 10.7211 0.840572 10.6229 0.591844C10.44 0.130907 10.0456 -0.079926 9.55878 0.0275572C9.26837 0.0916337 9.01034 0.224611 8.76816 0.390314C6.2678 2.10132 3.76789 3.81232 1.26845 5.52333C0.963914 5.73209 0.708985 5.98668 0.581176 6.34323C0.39687 6.85688 0.506765 7.31575 0.851263 7.72708C1.08277 8.00267 1.39247 8.18078 1.68357 8.38162C4.02156 9.98537 6.36058 11.5871 8.70064 13.1867C8.96431 13.3733 9.25659 13.5157 9.56602 13.6083L10.0177 13.6083Z"
          fill="#563BFF"
          style={{ fillOpacity: "0.75" }}
        />
      </svg>
    ),
    slider_right: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
      >
        <path
          d="M0.72063 13.6083C0.225931 13.4399 0.0602282 13.053 0.0192329 12.5731C-0.00867137 12.2459 0.00200804 11.9186 0.00200802 11.5923C0.000170554 8.18181 0.000170405 4.77129 0.00200758 1.36076C0.00200756 1.10067 0.0171655 0.840572 0.115347 0.591844C0.298275 0.130907 0.692725 -0.079926 1.1795 0.0275572C1.46991 0.0916337 1.72794 0.224611 1.97012 0.390314C4.47048 2.10132 6.97039 3.81232 9.46983 5.52333C9.77437 5.73209 10.0293 5.98668 10.1571 6.34323C10.3414 6.85688 10.2315 7.31575 9.88702 7.72708C9.65552 8.00267 9.34581 8.18078 9.05471 8.38162C6.71672 9.98537 4.3777 11.5871 2.03764 13.1867C1.77397 13.3733 1.48169 13.5157 1.17227 13.6083L0.72063 13.6083Z"
          fill="#563BFF"
          style={{ fillOpacity: "0.75" }}
        />
      </svg>
    ),
  };
  return (
    <React.Fragment>
      <div
        style={{ position: "relative" }}
        className="client-feedbacks"
      >
        <div style={{ position: "absolute", bottom: "50%", left: "-15px" }}>
          <button
            className="d-flex align-items-center justify-content-center font-primary"
            style={{
              padding: "0",
              borderRadius: "50%",
              border: "none",
              width: "30.739px",
              height: "30.739px",
              backgroundColor: "#FFF",
              boxShadow: "0px 4.098555564880371px 17.418861389160156px rgba(53, 22, 252, 0.10)",
            }}
          >
            {svgs.slider_left}
          </button>
        </div>
        <div
          className="font-primary w-100"
          style={{ overflowX: "auto", paddingBottom: "18px" }}
        >
          <div
            className="d-flex mx-auto"
            style={{ gap: "28.88px", width: "fit-content" }}
          >
            {data.map((item, index) => (
              <div
                key={index}
                className="d-flex dashboard-clients"
                // style={{ position: "relative" }}
              >
                <span className="dashboard-clients-icon_container">
                  <img
                    src={aiButtonicon}
                    width={32}
                  />
                </span>

                <p className="font-primary">{item.text}</p>
              </div>
            ))}
          </div>

          <div style={{ position: "absolute", bottom: "50%", right: "-15px" }}>
            <button
              className="d-flex align-items-center justify-content-center font-primary"
              style={{
                padding: "0",
                borderRadius: "50%",
                border: "none",
                width: "30.739px",
                height: "30.739px",
                backgroundColor: "#FFF",
                boxShadow: "0px 4.098555564880371px 17.418861389160156px rgba(53, 22, 252, 0.10)",
              }}
            >
              {svgs.slider_right}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RetoursClients;
