import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";

// import Components
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import EmailConfig from "../../Pages/E-mail/EmailConfig";
import RightSidebar from "../../components/Common/RightSideBar";
import { Modal } from "reactstrap";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  updateVisibility,
} from "../../store/actions";
import Chat from "../../components/Common/Chat";

const Layout = (props) => {
  const dispatch = useDispatch();

  const { layoutWidth, leftSideBarType, topbarTheme, showRightSidebar, leftSideBarTheme, show, thestate } = useSelector((state) => ({
    show: state.Modal.show,
    thestate: state,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  const handleButtonClick = () => {
    dispatch(updateVisibility(false));
  };

  //hides right sidebar on body click
  const hideRightbar = useCallback(
    (event) => {
      var rightbar = document.getElementById("right-bar");
      //if clicked in inside right bar, then do nothing
      if (rightbar && rightbar.contains(event.target)) {
        return;
      } else {
        //if clicked in outside of rightbar then fire action for hide rightbar
        dispatch(showRightSidebarAction(false));
      }
    },
    [dispatch]
  );

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);
  }, [hideRightbar]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  const data = useSelector((state) => state.variable_1);
  const [modalEmailConfig, setModalEmailConfig] = useState(false);
  const toggleEmailConfigModal = () => {
    setModalEmailConfig(!modalEmailConfig);
    // dispatch(updateVisibility(!modalEmailConfig)); // Toggle the show value on each click
  };
  // const interval = setInterval(() => {
  //   console.log("Modal visibility:", show); // Log the show value at regular intervals
  // }, 2000); // Log every 1000 milliseconds (1 second)
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={handleButtonClick} />
        <Sidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} />
        <div className="main-content font-primary"> {props.children} </div>
        {/* <Modal size="xl" isOpen={show} toggle={toggleEmailConfigModal}>
          <div className="modal-header font-primary">
            <h5 className="modal-title mt-0 font-primary" id="myExtraLargeModalLabel">
              Configuration
            </h5>
            <button onClick={handleButtonClick} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <EmailConfig></EmailConfig>
          </div>
        </Modal> */}
        <Chat />
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
