import React, { useEffect, useState } from "react";
import { createFeedBack } from "../../../helpers/api_helper";
import { Modal, ModalBody, Card, CardBody, Row, Col } from "reactstrap";
import Icons from "../../../static/icons";
import { withTranslation } from "react-i18next";

const Feedback = (props) => {
  const { isModalOpen, onClose } = props;
  const [togglePart, seTogglePart] = useState({ genral: false, input: false, valid: false });
  const [switchFeed, seTSwitchFeed] = useState({ issue: false, idea: false, other: false });
  const [loading, setLoading] = useState({ error: false, load: false });
  const [feedBackData, setFeedBackData] = useState({ comment: "", user_id: "", type: "" });
  const [feedBackType, setfeedBackType] = useState("");
  const user = localStorage.getItem("user");
  const userInfo = JSON.parse(user);
  useEffect(() => {
    seTogglePart({ genral: isModalOpen, input: false, valid: false });
  }, [isModalOpen]);
  const close_modal = () => {
    onClose();
    seTogglePart({ genral: false, input: false, valid: false });
    seTSwitchFeed({ issue: false, idea: false, other: false });
    setLoading({ error: false, load: false });
    setFeedBackData({ comment: "", user_id: "", type: "" });
  };
  const tog_input = (params) => {
    setfeedBackType(params);
    seTogglePart({ genral: false, input: true, valid: false });
    handleSwitchFeedback(params);
  };
  const handleSwitchFeedback = (params) => {
    setfeedBackType(params);
    seTSwitchFeed({ [params]: true });
  };
  const tog_valid = () => {
    seTogglePart({ genral: false, input: false, valid: true });
  };
  const handleUserFeedBack = async () => {
    try {
      setLoading((prev) => ({ ...prev, load: true }));
      const response = await createFeedBack(feedBackData);
      console.log({ response });
      if (response) {
        setLoading((prev) => ({ ...prev, load: false }));
        tog_valid();
      } else {
        setLoading((prev) => ({ ...prev, load: false, error: true }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, load: false, error: true }));
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setFeedBackData((prev) => ({
      ...prev,
      comment: value,
      user_id: userInfo.$id,
      type: feedBackType,
    }));
  };
  return (
    <Modal isOpen={isModalOpen} centered style={{ maxWidth: "30%" }}>
      <ModalBody>
        {togglePart.genral && (
          <div id="general_part">
            <div className="container">
              <div className="d-flex align-items-center justify-content-center w-100" style={{ position: "relative" }}>
                <h1 className="font-primary" style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {props.t("feedback")}
                </h1>

                <span className="" style={{ marginLeft: "auto", cursor: "pointer", right: "12px", position: "absolute" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="black"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                    onClick={close_modal}>
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </span>
              </div>
            </div>

            <Card>
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <Card className="feebackSection">
                      <CardBody onClick={() => tog_input("issue")} style={{ cursor: "pointer" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "10px", marginTop: "18px" }}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "15px" }}>
                            {Icons.issue}
                            <span className="font-primary feedDescription">{props.t("Issue")}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card className="feebackSection">
                      <CardBody onClick={() => tog_input("idea")} style={{ cursor: "pointer" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "10px", marginTop: "18px" }}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                            {Icons.idea}
                            <span className="font-primary feedDescription">{props.t("Idea")}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card className="feebackSection">
                      <CardBody onClick={() => tog_input("other")} style={{ cursor: "pointer" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "10px", marginTop: "18px" }}>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "7px" }}>
                            {Icons.cloud}
                            <span className="font-primary feedDescription">{props.t("Other")}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        )}
        {togglePart.input && (
          <div id="input_part">
            <div className="container">
              <div className="d-flex align-items-center justify-content-center w-100" style={{ position: "relative" }}>
                <h1 className="font-primary" style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {props.t("feedback")}
                </h1>

                <span className="" style={{ marginLeft: "auto", cursor: "pointer", right: "0px", position: "absolute" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="black"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                    onClick={close_modal}>
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-wrap gap-3"
              style={{ marginTop: "15px", display: "flex", alignItems: "center", height: "70px", justifyContent: "center" }}>
              <Card
                className={`feedBack-switch ${switchFeed.issue ? "feedBack-switch-seclected" : ""}`}
                onClick={() => handleSwitchFeedback("issue")}>
                <CardBody>
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#e92000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-mark">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 19v.01" />
                      <path d="M12 15v-10" />
                    </svg>
                    <span className="feedDescription">{props.t("Issue")}</span>
                  </div>
                </CardBody>
              </Card>
              <Card className={`feedBack-switch ${switchFeed.idea ? "feedBack-switch-seclected" : ""}`} onClick={() => handleSwitchFeedback("idea")}>
                <CardBody>
                  <div className="d-flex flex-wrap gap-2">
                    <svg width="24" height="24" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.75463 4C1.65672 5.3696 1 7.10811 1 9C1 11.9482 3.2263 14.3013 4.70778 15.5408C5.47633 16.1838 6 17.1102 6 18.1123V18.1123C6 19.7071 7.29286 21 8.88769 21H9.12679C10.7136 21 12 19.7136 12 18.1268V18.1268C12 17.1175 12.5342 16.1861 13.3119 15.5427C14.7933 14.317 17 11.9895 17 9C17 4.58172 13.4183 1 9 1C7.9391 1 6.92643 1.20651 6 1.58152M6 17C6 17 7 17.5 9 17.5"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="feedDescription">{props.t("Idea")}</span>
                  </div>
                </CardBody>
              </Card>
              <Card
                className={`feedBack-switch ${switchFeed.other ? "feedBack-switch-seclected" : ""}`}
                onClick={() => handleSwitchFeedback("other")}>
                <CardBody>
                  <div className="d-flex flex-wrap gap-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.4806 9.0452C21.5395 9.86433 23 11.9231 23 14.3333C23 17.4629 20.5376 20 17.5 20H5.4C2.96995 20 1 17.9704 1 15.4667C1 13.3539 2.40285 11.5786 4.30122 11.0758C4.30041 11.0284 4.3 10.9809 4.3 10.9333C4.3 6.55187 7.74741 3 12 3C13.9084 3 15.6547 3.71532 17 4.89992"
                        stroke="#2F384C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="feedDescription">{props.t("Other")}</span>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="d-flex flex-column">
              <div
                style={{
                  border: "solid",
                  borderColor: "#6d53c8",
                  borderWidth: "1px",
                  width: "100%",
                  borderRadius: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "15px",
                  paddingRight: "18px",
                }}>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    overflowY: "auto",
                    border: "none",
                    borderRadius: "20px",
                    overflowX: "hidden",
                  }}
                  onChange={handleInputChange}
                />
              </div>
              {loading.error && <em style={{ color: "#ef2951", textAlign: "center" }}>{props.t("feedbackError")}</em>}
              <button
                type="button"
                className="view-button"
                style={{
                  marginTop: "15px",
                  width: "100%",
                  backgroundColor: "#563BFF",
                }}
                disabled={(feedBackData.comment.length <= 0 ? true : false) || loading.load}
                onClick={handleUserFeedBack}>
                {loading.load && <span className="spinner-border spinner-border-sm me-2 font-primary" role="status" aria-hidden="true" />}
                {props.t("sendFeedback")}
              </button>
            </div>
          </div>
        )}
        {togglePart.valid && (
          <div id="valid_part">
            <div>
              <div className="d-flex w-100">
                <span className="" style={{ marginLeft: "auto", cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="black"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                    onClick={close_modal}>
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  height: "30vh",
                  flexDirection: "column",
                }}>
                <div>
                  <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>
                </div>
                <div>
                  <p className="feedDescription">{props.t("messageFeedback")}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(Feedback);
