import React from "react";

function RefreshCcwOutlined(props) {
  const { width, height, className } = props;
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 12C2 14.2516 2.74418 16.3295 4 18.001M22 12C22 9.74879 21.2615 7.67132 20.0104 6M2 7.5V4M2 7.5C2 7.5 5.33333 2 12 2C13.8262 2 15.5323 2.48377 17 3.32943M2 7.5H5.5M21 16.5C21 16.5 17.5228 22 12 22C10.1786 22 8.47087 21.513 7 20.6622M21 16.5H17.5M21 16.5V20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default RefreshCcwOutlined;
