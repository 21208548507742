import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import logo_maaia from "../../assets/images/maaia-logo.png";
import clients_satisfaction from "../../assets/images/clients_satisfaction.png";

const stats = {
  "1 semaine": {
    labels: ["2024-04-01", "2024-04-02", "2024-04-03", "2024-04-03"],
    datasets: [
      {
        label: "Content",
        lineTension: 0.2,
        borderColor: ["#329993"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#329993",
        data: [35, 15, 28, 20],
      },
      {
        label: "Mécontent",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#ff7049"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#ff7049",
        data: [0, 2, 0, 0],
      },
      {
        label: "Neutre",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["grey"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "grey",
        data: [10, 40, 24, 12],
      },
    ],
  },
  "1 mois": {
    labels: ["2024-04-04", "2024-04-05", "2024-04-06", "2024-04-07"],
    datasets: [
      {
        label: "Content",
        lineTension: 0.2,
        borderColor: ["#329993"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#329993",
        data: [130, 54, 90, 80],
      },
      {
        label: "Mécontent",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#ff7049"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#ff7049",
        data: [0, 9, 2, 1],
      },
      {
        label: "Neutre",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["grey"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "grey",
        data: [45, 110, 96, 53],
      },
    ],
  },
  "3 mois": {
    labels: ["2024-04-07", "2024-04-08", "2024-04-09", "2024-04-10"],
    datasets: [
      {
        label: "Content",
        lineTension: 0.2,
        borderColor: ["#329993"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#329993",
        data: [396, 130, 200, 237],
      },
      {
        label: "Mécontent",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#ff7049"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#ff7049",
        data: [10, 11, 7, 8],
      },
      {
        label: "Neutre",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["grey"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "grey",
        data: [123, 290, 288, 159],
      },
    ],
  },
};

const SentimentChart = (props) => {
  const [formattedDataList, setformattedDataList] = useState([]);
  const period = props.period;

  const getMessageData = (period) => {
    const data = stats[period];
    console.log("dataForGraphic is", data);
    setformattedDataList(data);
  };

  var option = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 50,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };
  useEffect(() => {
    getMessageData(period);
  }, [period]);
  return (
    <div
      className="font-primary"
      style={{
        position: "relative",
        display: "grid",
        gridTemplateRows: "minmax(0,3fr)",
        height: formattedDataList && formattedDataList.labels?.length > 0 ? "86%" : "100%",
      }}
    >
      {formattedDataList && formattedDataList.labels?.length > 0 ? (
        <Line
          data={formattedDataList}
          options={option}
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + clients_satisfaction + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "119%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="font-primary font-size-22 color-black mt-5">En attente de données</div>
          <img
            src={logo_maaia}
            height={40}
          />
        </div>
      )}
    </div>
  );
};

export default SentimentChart;
