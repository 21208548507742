import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Modal, Row, Toast, ToastHeader, ToastBody, Collapse } from "reactstrap";
import { fakeAlertList } from "./AlertZone";
import classnames from "classnames";
import getFrenchTag from "../../helpers/local_text_helper";
import { withTranslation } from "react-i18next";
import { Client, Account, Databases, ID, Query } from "appwrite";

function AllAlerts(props) {
  const [groupedAlerts, setGroupedAlerts] = useState();
  const [expandedAlert, setExpandedAlert] = useState(undefined);
  const onExpandedAlert = (id) => {
    setExpandedAlert(id);
  };
  const stringToColour = (str) => {
    let hash = 0;
    str.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }

    // Vérifiez si la couleur générée est dans la plage de jaune
    if (/^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(colour)) {
      const hexValue = colour.replace("#", "");
      const r = parseInt(hexValue.slice(0, 2), 16);
      const g = parseInt(hexValue.slice(2, 4), 16);
      const b = parseInt(hexValue.slice(4, 6), 16);

      // YELLOW
      if (r >= 200 && g >= 200 && b < 100) {
        colour = "#FF5733";
      }

      // WHITE
      const whiteThreshold = 200;
      if (r >= whiteThreshold && g >= whiteThreshold && b >= whiteThreshold) {
        colour = "#333333";
      }
    }

    return colour;
  };

  const companyId = localStorage.getItem("companyId");

  async function getAlert() {
    try {
      if (!companyId) {
        return;
      }

      const client = new Client();
      const databases = new Databases(client, "default");
      client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

      const response = await databases.listDocuments("default", "alert", [
        Query.equal("active", true),
        Query.equal("company", companyId),
        Query.orderDesc("$createdAt"),
      ]);
      console.log("getAlert", response.documents);
      setGroupedAlerts(groupAlerts(response.documents));
    } catch (error) {
      console.error("Failed to fetch alert data:", error);
      throw error; // Vous pouvez relancer l'erreur ou gérer l'erreur de manière appropriée ici
    }
  }

  async function updateAlert(alert_id, group) {
    console.log("updateAlert", alert_id);
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    try {
      await databases.updateDocument("default", "alert", alert_id, { active: false });
      console.log("Alert deleted successfully");
      setGroupedAlerts({ ...groupedAlerts, [group]: groupedAlerts[group].filter((alert) => alert.$id !== alert_id) });
      console.log("updated alerts", { ...groupedAlerts, [group]: groupedAlerts[group].filter((alert) => alert.$id !== alert_id) });
    } catch (error) {
      console.log("Error updating alert:", error);
    }
  }

  // Obtient la date d'aujourd'hui à minuit
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Groupe les Alertes par période depuis la réception
  const groupAlerts = (sortedAlerts) => {
    return sortedAlerts.reduce((groups, alert) => {
      const createdAt = new Date(alert.$createdAt);
      const diffTime = today - createdAt;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Différence en jours

      let label = "";
      if (diffDays === 0) {
        label = "Aujourd’hui";
      } else if (diffDays === 1) {
        label = "Hier";
      } else if (diffDays <= 3) {
        label = "3 jours";
      } else if (diffDays <= 7) {
        label = "1 semaine";
      } else if (diffDays <= 30) {
        label = "1 mois";
      } else if (diffDays <= 90) {
        label = "3 mois";
      } else {
        label = "Ancien";
      }

      if (!groups[label]) {
        groups[label] = [];
      }
      groups[label].push(alert);
      return groups;
    }, {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAlert();
  }, []);
  function formatDate(dateString) {
    const options = { day: "2-digit", month: "short", year: "2-digit", hour: "2-digit", minute: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", options);
  }
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ height: "100vh" }}
      >
        <Container
          fluid
          className="h-100 px-3"
          style={{
            padding: "0",
            width: "60%",
            overflow: "auto",
          }}
        >
          <div
            className="page-title-box d-sm-flex align-items-center justify-content-between"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <h4
              className="page-title font-primary"
              style={{ fontSize: "", marginTop: "18px" }}
            >
              {props.t("Attentions Requises")}
            </h4>
          </div>
          <div className="mx-3 d-flex flex-column attention_requested">
            <Row className="h-100">
              <Card
                style={{
                  background: "transparent",
                  boxShadow: "none",
                  padding: "0px",
                  marginBottom: "0",
                }}
              >
                <CardBody
                  className="d-flex flex-column"
                  style={{
                    padding: "0",
                  }}
                >
                  <div
                    className="accordion accordion-flush px-0 font-primary"
                    id="accordionFlushExample"
                    style={{
                      flex: "1",
                      overflowY: "auto",
                    }}
                  >
                    {groupedAlerts &&
                      Object.entries(groupedAlerts).map(([label, alerts], idx) => (
                        <div key={label}>
                          <div className="w-100 d-flex justify-content-end">
                            <h2
                              className="font-primary font-size-16 mb-4"
                              style={{
                                // minWidth: "116px",
                                color: "grey",
                              }}
                            >{`${label} —`}</h2>
                          </div>

                          {alerts.map((alert) => (
                            <div
                              key={alert.$id}
                              className={`accordion-item ${expandedAlert === alert.$id && "deployed"}`}
                            >
                              <h2
                                className="accordion-header"
                                id="headingFlushOne"
                              >
                                <div
                                  className={classnames("accordion-button", "fw-medium", "font-primary", {
                                    collapsed: expandedAlert !== alert.$id,
                                  })}
                                  type="button"
                                  onClick={() => {
                                    if (expandedAlert === alert.$id) {
                                      onExpandedAlert(false);
                                    } else {
                                      onExpandedAlert(alert.$id);
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span className="title w-100">{alert.message}</span>
                                </div>
                              </h2>

                              <Collapse
                                isOpen={expandedAlert === alert.$id}
                                className="accordion-collapse"
                              >
                                <div className="accordion-body">
                                  <div className="text-muted accordion-body__text font-primary">
                                    <p>{alert.message}</p>
                                    <span style={{ textTransform: "capitalize", fontWeight: "500" }}>{formatDate(alert.$createdAt)}</span>
                                    <Col lg={4}>
                                      {alert.contact_reasons &&
                                        alert.contact_reasons.map((reason, index) => (
                                          <div
                                            key={index}
                                            className={`badge me-1 font-primary`}
                                            style={{
                                              backgroundColor: `${stringToColour(reason)}25`,
                                              color: stringToColour(reason),
                                              font: "normal 400 11px / normal Poppins, sans-serif !important",
                                              marginTop: "6px",
                                              display: "flex !important",
                                            }}
                                          >
                                            {getFrenchTag(reason)}
                                          </div>
                                        ))}
                                    </Col>
                                  </div>
                                  <div className="">
                                    <button
                                      className="action-btn font-primary"
                                      style={{
                                        backgroundColor: "black",
                                        marginRight: "20px",
                                      }}
                                    >
                                      Consulter
                                    </button>
                                    <button
                                      className="action-btn font-primary"
                                      style={{
                                        background: "#563BFF",
                                        marginRight: "20px",
                                      }}
                                    >
                                      Valider
                                    </button>
                                    <button
                                      className="action-btn font-primary"
                                      style={{
                                        backgroundColor: "#FF7049",
                                        boxShadow: "0px 14.34494px 36.887px 0px rgba(255, 73, 73, 0.20)",
                                      }}
                                      onClick={() => updateAlert(alert.$id, label)}
                                    >
                                      Fermer
                                    </button>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(AllAlerts);
