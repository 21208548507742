import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./rangeDatepicker.scss";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { Button } from "reactstrap";
import ArrowRightOutlined from "../../../assets/images/icons/ArrowRightOutlined";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { withTranslation } from "react-i18next";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";

const locales = {
  en: en,
  fr: fr,
  es: es,
};
function RangeDatepicker({ period, onPeriodChange, t, i18n }) {
  const [btn, setbtn] = useState(false);

  return (
    <Dropdown
      isOpen={btn}
      toggle={() => setbtn(!btn)}
    >
      <DropdownToggle
        tag="button"
        className="btn"
        style={{
          backgroundColor: "#FFEDBB",
          color: "black",
          fontWeight: "bold",
          minWidth: "300px",
        }}
      >
        {`Période : ${period}`}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-start w-100 p-0">
        <DropdownItem onClick={() => onPeriodChange("1 semaine")}>1 semaine</DropdownItem>
        <DropdownItem onClick={() => onPeriodChange("1 mois")}>1 mois</DropdownItem>
        <DropdownItem onClick={() => onPeriodChange("3 mois")}>3 mois</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default withTranslation()(RangeDatepicker);
