import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import logo_maaia from "../../assets/images/maaia-logo.png";
import turnover from "../../assets/images/turnover.png";
const stats = {
  "1 semaine": {
    series: [
      {
        name: "Chiffre d'Affaires",
        data: [
          { x: "2024-04-08", y: 1100 },
          { x: "2024-04-09", y: 1200 },
          { x: "2024-04-10", y: 1300 },
          { x: "2024-04-11", y: 1500 },
          { x: "2024-04-12", y: 1800 },
          { x: "2024-04-13", y: 2100 },
          { x: "2024-04-14", y: 2347 },
        ],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#6aeb9f"],
      xaxis: {
        type: "datetime",
        categories: ["2024-04-05", "2024-04-06", "2024-04-07", "2024-04-08", "2024-04-09", "2024-04-10", "2024-04-11"],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  },
  "1 mois": {
    series: [
      {
        name: "Chiffre d'Affaires",
        data: [
          { x: "2024-04-08", y: 4625 },
          { x: "2024-04-09", y: 5662 },
          { x: "2024-04-10", y: 6684 },
          { x: "2024-04-11", y: 7889 },
          { x: "2024-04-12", y: 8983 },
          { x: "2024-04-13", y: 9087 },
          { x: "2024-04-14", y: 10561 },
        ],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#6aeb9f"],
      xaxis: {
        type: "datetime",
        categories: ["2024-04-01", "2024-04-08", "2024-04-15", "2024-04-22", "2024-04-29"],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  },
  "3 mois": {
    series: [
      {
        name: "Chiffre d'Affaires",
        data: [
          { x: 1634978400000, y: 10087 },
          { x: 1635064800000, y: 18092 },
          { x: 1635151200000, y: 23012 },
          { x: 1635237600000, y: 35021 },
          { x: 1635324000000, y: 47683 },
        ],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#6aeb9f"],
      xaxis: {
        type: "datetime",
        categories: [1634546400000, 1634632800000, 1634719200000, 1634805600000, 1634892000000],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  },
};
const Turnover = (props) => {
  const period = props.period;
  const [formattedDataList, setformattedDataList] = useState({
    series: [
      {
        name: "CA",
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#F10944"],
      xaxis: {
        type: "datetime",
        categories: [],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  });

  const handleDateChange = (period) => {
    setformattedDataList(stats[period]);
  };
  useEffect(() => {
    handleDateChange(period);
  }, [period]);

  return (
    <React.Fragment>
      {formattedDataList && formattedDataList.series[0].data.length > 0 ? (
        <ReactApexChart
          options={formattedDataList.options}
          series={formattedDataList.series}
          type="area"
          height="235"
          className="apex-charts font-primary"
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + turnover + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "138%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        >
          <div className="font-primary font-size-22 color-black mt-5">En attente de données</div>
          <img
            src={logo_maaia}
            height={40}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Turnover;
