import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../withRouter";

// users
import { getCompanyInfos } from "../../../helpers/api_helper";
import UserOutlined from "../../../assets/images/icons/UserOutlined";
import LockOutlined from "../../../assets/images/icons/LockOutlined";
import UsersOutlined from "../../../assets/images/icons/UsersOutlined";
import MonitorOutlined from "../../../assets/images/icons/MonitorOutlined";
import FileTextOutlined from "../../../assets/images/icons/FileTextOutlined";
import RefreshCcwOutlined from "../../../assets/images/icons/RefreshCcwOutlined";
import PowerOutlined from "../../../assets/images/icons/PowerOutlined";

const ProfileMenu = (props) => {
  // console.log({ props });

  const navigate = useNavigate();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const getAvatar = () => {
    if (props.user_infos) {
      if (props.user_infos.prefs && props.user_infos.prefs["first_name"] && props.user_infos.prefs["first_name"] !== "") {
        return `${props.user_infos.prefs["first_name"]} ${props.user_infos.prefs["last_name"]}`
          .split(" ")
          .map((part) => part.charAt(0).toUpperCase())
          .join("")
          .slice(0, 2);
      } else {
        return "Admin".charAt(0).toUpperCase();
      }
    }
  };
  const getFullName = () => {
    let full_name = "";
    if (props.user_infos) {
      if (props.user_infos.prefs && props.user_infos.prefs["first_name"] && props.user_infos.prefs["first_name"] !== "") {
        full_name = props.user_infos.prefs["first_name"];
      }
      if (props.user_infos.prefs && props.user_infos.prefs["last_name"] && props.user_infos.prefs["last_name"] !== "") {
        full_name = full_name + " " + props.user_infos.prefs["last_name"];
      }
      if (full_name === "") {
        full_name = "Admin";
      }
    } else {
      full_name = "";
    }
    return full_name;
  };

  const handleClick = (val, mod) => {
    // Remplacez cela par la valeur que vous souhaitez passer
    navigate(`/settings`, {
      replace: true,
      state: { tab: val, modal: mod ?? "" },
    });
  };

  useEffect(() => {
    handleUserCompanyInfo();
  }, [props.success, props.user_infos, navigate]);

  const handleUserCompanyInfo = async () => {
    const userId = localStorage.getItem("companyId");
    // console.log({ userId });
    if (userId) {
      const companyInfo = await getCompanyInfos();
      if (companyInfo && companyInfo.company && companyInfo.company !== "") {
        setCompanyName(companyInfo.company);
      } else {
        setCompanyName("Projet");
      }
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="user-dropdown d-inline-block"
      >
        <DropdownToggle
          className="p-0 bg-transparent border-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img className="header-profile-user" src={user1} alt="Header Avatar" /> */}
          {/* <div
            className="d-flex align-items-center justify-content-center"
            style={{
              fontSize: '120%',
              backgroundColor: 'grey',
              color: 'white',
              padding: '0',
              borderRadius: '13.511px',
              textAlign: 'center',
              width: '50.667px',
              height: '50.667px',
            }}>
            {(props.user_infos
              ? props.user_infos.prefs && props.user_infos.prefs['first_name'] && props.user_infos.prefs['first_name'] !== "" ? props.user_infos.prefs['first_name']
                ? `${props.user_infos.prefs['first_name']} ${props.user_infos.prefs['last_name']}`
                : 'Admin'
              : ''
            )
              .split(' ')
              .map((part) => part.charAt(0).toUpperCase())
              .join('')}
          </div> */}
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "20px" }}>
            <span className="user-name d-none d-xl-inline-block ms-3 font-primary">
              {/* {username} */}
              {companyName}
            </span>

            <div
              className="d-flex justify-content-between font-primary"
              style={{ marginLeft: "20px" }}
            >
              <span
                className="me-1 font-primary"
                style={{
                  color: "#737791",

                  fontSize: "11.822px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "16.889px",
                }}
              >
                {props.user_infos
                  ? props.user_infos.prefs && props.user_infos.prefs["first_name"] && props.user_infos.prefs["first_name"] !== ""
                    ? props.user_infos.prefs["first_name"]
                    : "Admin"
                  : ""}
              </span>
              <span>
                <i className="user-chevron-down-icon mdi mdi-chevron-down d-none d-xl-inline-block" />
              </span>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end dropdown-menu-md page-header-user-dropdown_menu font-primary">
          <DropdownItem
            disabled
            tag="a"
          >
            <div
              className="d-flex gap-1 row"
              style={{ flexWrap: "nowrap" }}
            >
              <div
                className="col-auto d-flex align-items-center justify-content-center font-primary"
                style={{
                  fontSize: "120%",
                  backgroundColor: "grey",
                  color: "white",
                  padding: "0",
                  borderRadius: "50%",
                  textAlign: "center",
                  width: "40px",
                  height: "40px",
                  flexShrink: "0",
                }}
              >
                {getAvatar()}
              </div>
              <div
                className="col-auto p-0 ms-1 d-flex flex-column"
                style={{}}
              >
                <p
                  className="mb-0 font-size-13 font-primary"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "170px",
                    color: "#091b3d",
                  }}
                >
                  {getFullName()}
                </p>
                <span
                  className="font-size-12 font-primary"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "170px",
                  }}
                >
                  {props.user_infos && props.user_infos.email}
                </span>
              </div>
            </div>
          </DropdownItem>

          <DropdownItem
            disabled
            className="pb-0 font-primary"
          >
            {props.t("Account")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("profile");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-user-line align-middle me-2" /> */}
            <UserOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Profile")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("profile", "password");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-lock-password-line align-middle me-2" /> */}
            <LockOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Mot de passe")}
          </DropdownItem>
          <div className="mt-2"></div>
          <DropdownItem
            disabled
            className="pb-0 font-primary"
          >
            {props.t("Company")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("users");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-group-line align-middle me-2" /> */}
            <UsersOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Users")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-computer-line align-middle me-2" /> */}
            <MonitorOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Workspaces")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("billing");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-file-text-line align-middle me-2" /> */}
            <FileTextOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Billing")}
          </DropdownItem>
          <div className="mt-3"></div>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-refresh-line align-middle me-2" /> */}
            <RefreshCcwOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Switch company")}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <i className="ri-wallet-2-line align-middle me-2" />
            {props.t('My Wallet')}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end mt-1">11</span>
            <i className="ri-settings-2-line align-middle me-2" />
            {props.t('Settings')}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="ri-lock-unlock-line align-middle me-2" />
            {props.t('Lock screen')}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <Link
            to="/logout"
            className="dropdown-item dropdown-item-py-1 dropdown-item-mb-1 text-danger"
          >
            {/* <i className="ri-shut-down-line align-middle me-2" /> */}
            <PowerOutlined
              height="20"
              width="20"
              className="me-2"
            />
            <span style={{ fontSize: "14px" }}>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
