import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { FETCH_MAILS_REDUX, FETCH_FOLDERS_REDUX } from "./actionTypes";
import { applyMailsSuccessful, applyMailsError, applyFoldersSuccessful, applyFoldersError } from "./actions";

//Include Both Helper File with needed methods
import { fetchMailsList, fetchFoldersList } from "../../helpers/api_helper";

// Is user register successfull then direct plot user in redux.
function* getMails({ payload: { company_id, box, currentPage, pageSize, old_messages } }) {
  console.log("started mails fectch");

  try {
    const data = yield call(fetchMailsList, company_id, box, currentPage, pageSize, old_messages);
    if (typeof data === "string" && data.includes("error")) {
      yield put(applyMailsError({ data, box, currentPage, old_messages }));
    } else {
      console.log("Mails fetched successfully", data);
      yield put(applyMailsSuccessful({ data, box, currentPage, old_messages }));
    }
  } catch (error) {
    console.log("Mails fetche failed", error);
    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      case 401:
        message = "E-mail ou mot de passe incorrect";
        break;
      case 409:
        message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
        break;
      default:
        message = error.message;

        yield put(applyMailsError({ message, box, currentPage, old_messages }));
    }
  }
}

function* getFolders({ payload: { company_id } }) {
  // console.log("started folders fetch for id", company_id);

  try {
    const data = yield call(fetchFoldersList, company_id);
    if (!data || (data && typeof data === "string" && data.includes("error"))) {
      console.log("1. Boxes fetch error", data);
      yield put(applyFoldersError(data));
    } else {
      // console.log("Folders fetched successfully", data);
      yield put(applyFoldersSuccessful({ data, id: company_id }));
    }
  } catch (error) {
    console.log("2. Boxes fetch error", error);
    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      case 401:
        message = "E-mail ou mot de passe incorrect";
        break;
      case 409:
        message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
        break;
      default:
        message = error.message;

        yield put(applyFoldersError(message));
    }
  }
}

export function* watchFetchMails() {
  yield takeEvery(FETCH_MAILS_REDUX, getMails);
}
export function* watchFetchFolders() {
  yield takeEvery(FETCH_FOLDERS_REDUX, getFolders);
}

function* mailsSaga() {
  yield all([fork(watchFetchMails), fork(watchFetchFolders)]);
}

export default mailsSaga;
