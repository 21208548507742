import React, { useState, useEffect } from "react";
import GaugeChart from "react-gauge-chart";
import { getUserCompanyInfos } from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { isCreditAvailable } from "../../store/actions";

const chartStyle = {
  width: "100%",
  //   height: "44px",
};

const LiveGaugeChart = () => {
  const [company_infos, setCompanyInfos] = useState(undefined);
  const dispatch = useDispatch();

  const { credit } = useSelector((state) => ({
    credit: state.GeneralStateRedux.credit,
  }));
  // console.log({ company_infos });
  // console.log({ credit });

  const handleUserCompanyInfo = async () => {
    try {
      const userId = localStorage.getItem("companyId");
      if (userId) {
        const response = await getUserCompanyInfos(userId);
        if (response) {
          dispatch(isCreditAvailable(true));
          if (!company_infos) {
            setCompanyInfos(response);
          } else if (response.token_usage !== company_infos.token_usage) {
            setCompanyInfos(response);
          }
        }
      }
    } catch (error) {
      dispatch(isCreditAvailable(false));
    }
  };
  // Simulate live data updates
  useEffect(() => {
    handleUserCompanyInfo();
  }, [credit]);

  return (
    <div className="d-flex align-items-center">
      <div style={{ width: "77px", height: "44px" }}>
        <GaugeChart
          id="gauge-chart1"
          style={chartStyle}
          marginInPercent={0}
          cornerRadius={0}
          nrOfLevels={6}
          // percent={value}
          percent={1}
          arcPadding={0.03}
          arcWidth={0.23}
          colors={["red", "#FF805F", "#FFB49F", "#9FBF8F", "#5B8C5A", "green", "#41d128"]}
          needleColor={"#151D48"}
          needleBaseColor={"#151D48"}
          hideText={true}
          // arcsLength={[0.3, 0.17, 0.17, 0.17, 0.17, 0.3]}
          animate={false}
          // animDelay={500}
          // animateDuration={3000}
          // formatTextValue={null}
        />
      </div>
      <div className="gauge-stats-container ms-3">
        <span
          className="gauge-stats__number"
          style={{
            color: "#9DA4B1",
            fontFamily: "Poppins, sans-serif",
            fontSize: " 14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          2000
          {" / "}
        </span>
        <span
          className="gauge-stats__total"
          style={{
            color: "#151D48",
            fontFamily: "Poppins, sans-serif",
            fontSize: " 14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          2000
        </span>
      </div>
    </div>
  );
};

export default LiveGaugeChart;
