import axios from "axios";
import config from "../config";
import { Client, Account, ID, Databases, Query } from "appwrite";

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const appwriteClient = new Client();
appwriteClient.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const getLoggedinUser = async () => {
  const user = localStorage.getItem("authUser");
  // const user = await checkSession();
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

async function getCompanyInfos() {
  const client = new Client();

  const databases = new Databases(client, "default");

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const user_data = localStorage.getItem("authUser");
  if (!user_data) return undefined;

  const promise = databases.getDocument("default", "client_infos", JSON.parse(user_data).userId);

  return await promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      return undefined;
    }
  );
}

async function getUser() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.get();

  return promise
    .then(
      function (response) {
        // console.log("user is", response);
        localStorage.setItem("user", JSON.stringify(response));
        return response;
      }
      // function (error) {
      //   // console.log('error on session check', error);
      //   return undefined;
      // }
    )
    .catch(function (error) {
      // console.log("error on session check", error);
      return undefined;
    });
}
async function getEmailSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.getSession("current");

  promise.then(
    function (response) {
      // // console.log('Success on session check at login page');
      // // console.log(response);
    },
    function (error) {
      // console.log("error on session check", error);
    }
  );
}

async function createEmailAccount(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.create(ID.unique(), email, password);

  /*
  await promise.then(
    function (response) {
      // console.log('Success email session created');
      // console.log(response);
      localStorage.setItem('authUser', JSON.stringify(response));
      return response;
    },
    function (error) {
      // console.log('error on email session create', error);

      let message;
      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        case 404:
          message = 'Sorry! the data you are looking for could not be found';
          break;
        default:
          message = error.message || error;
      }

      return message;
    }
  );
  */
}

async function createEmailSessionPromise(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.createEmailSession(email, password);
}

async function createEmailSession(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.createEmailSession(email, password);

  await promise.then(
    function (response) {
      // console.log("Success email session created");
      // console.log(response);
      // localStorage.setItem('authUser', JSON.stringify(response));
      return response;
    },
    function (error) {
      // console.log("error on email session create", error);
      return null;
    }
  );
}

async function updateUserPrefs(thePrefs) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.updatePrefs(thePrefs);

  await promise.then(
    function (response) {
      // console.log("Success user prefs updated");
      // console.log(response);
      return response;
    },
    function (error) {
      // console.log("error on user prefs update", error);
      return null;
    }
  );
}

async function fetchMailsList(company_id, box, currentPage, pageSize) {
  // setShowReadEmailView(false);

  const apiUrl = "https://mailer-server-test.semecloud.tech/get-threads";
  // const apiUrl = "http://localhost:55000/get-threads";
  // console.log("fetchMailsList launched", currentPage);
  return axios.get(apiUrl, {
    params: {
      company_id: company_id,
      folder: box ? box : "INBOX",
      page: currentPage ?? 1,
      limit: pageSize ?? 20,
      day_since: box !== "INBOX" ? 40 : 40,
    },
  });
}

async function shopifyOauth(shop) {
  const apiUrl = "http://localhost:50001/auth-2";
  // return await axios.get(apiUrl, {
  //   mode: "no-cors",
  //   params: {
  //     shop,
  //   },
  // });

  try {
    const response = await axios.get(apiUrl, {
      // headers: {
      //   "X-Shopify-Access-Token": shopify_api_key,
      // },
      params: {
        shop,
      },
    });

    if (response.status === 304 || response.status === 303 || response.status === 200) {
      const the_data = response.data;
      // console.log("response is", response);
      // console.log("the_data is", the_data);
      // console.log("redirectUrl is", the_data.auth_url);
      const redirectUrl = the_data.auth_url;
      // Effectuer la redirection si une URL de redirection est présente
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // console.log("no redirectUrl");
      }
    } else {
      // console.log("Error is", response);
      window.location.href = response.auth_url;
    }
  } catch (error) {
    // console.log("Error", error);
  }
}

// async function shopifyOauth(shop) {
//   const apiUrl = `http://localhost:50001/auth-2?shop=${shop}`;

//   try {
//     const response = await fetch(apiUrl, {
//       method: "GET",
//       mode: "no-cors",
//     });
//     // console.log("response is", response);
//     // console.log("redirectUrl is", response.body);
//     const redirectUrl = response.auth_url;
//     // Effectuer la redirection si une URL de redirection est présente
//     if (redirectUrl) {
//       window.location.href = redirectUrl;
//     } else {
//       // console.log("no redirectUrl");
//     }
//   } catch (error) {
//     console.error("Error shopifyOauth:", error);
//     throw error;
//   }
// }

async function shopifyOauthCallback(code, hmac, host, shop, state, timestamp) {
  const apiUrl = `http://localhost:50001/auth/callback?code=${code}&hmac=${hmac}&host=${host}&shop=${shop}&state=${state}&timestamp=${timestamp}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "no-cors",
    });
    const redirectUrl = response.url;
    // Effectuer la redirection si une URL de redirection est présente
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      // console.log("no redirectUrl");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

async function fetchFoldersList(id) {
  // onBoxesLoading(true);
  const apiUrl = "https://mailer-server-test.semecloud.tech/get-boxes";
  // const apiUrl = "http://localhost:55000/get-boxes";
  return axios.get(apiUrl, {
    params: {
      company_id: id,
    },
  });
}

async function logoutUserSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.deleteSessions();
}

async function createLog(data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    const response = await databases.createDocument("default", "logs", ID.unique(), data);

    // console.log("Log created ", response); // Success
    return response;
  } catch (error) {
    // console.log("Error creating Log ", error); // Gestion de l'erreur
    return null;
  }
}

async function createFeedBack(data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    const response = await databases.createDocument("default", "feedback", ID.unique(), data);

    // console.log("Feedback created ", response); // Success
    return response;
  } catch (error) {
    // console.log("Error creating Feedback ", error); // Gestion de l'erreur
    return null;
  }
}

async function getEmployeeLink(userId) {
  const client = new Client();
  const databases = new Databases(client, "default");

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = databases.listDocuments("default", "employee_link", [Query.equal("employee_id", userId)]);

  return promise.then(
    async function (response) {
      // console.log("Sidebar.js: Employee link is ", response.documents);
      return response.documents;
    },
    function (error) {
      // console.log("Error creating Employee link", error); // Failure
      return null;
    }
  );
}
async function getItemFromChargeBee(limit) {
  // const apiUrl = `http://localhost:3000/listItem`;
  // const params = { limit: limit };
  // return axios
  //   .get(apiUrl, { params })
  //   .then((response) => {
  //     // console.log({ response });

  //     return response;
  //   })
  //   .catch((error) => {
  //     // console.log("Failed copying msg", error);
  //     return [];
  //   });
  return [
    {
      external_name: "Trial",
      applicable_items: [
        {
          id: "day-pass",
        },
      ],
      enabled_for_checkout: true,
      enabled_in_portal: true,
      id: "trial",
      is_giftable: false,
      is_shippable: false,
      item_applicability: "restricted",
      name: "Trial",
      amount_per_mail: "+ 0,04€/email supp.",
      payment_link: "https://google.com/",
      object: "item",
      resource_version: 1599817250235,
      status: "active",
      type: "plan",
      updated_at: 1599817250,
    },
    {
      external_name: "Basic",
      applicable_items: [
        {
          id: "day-pass",
        },
      ],
      enabled_for_checkout: true,
      enabled_in_portal: true,
      id: "basic",
      is_giftable: false,
      is_shippable: false,
      item_applicability: "restricted",
      name: "Basic",
      amount_per_mail: "+ 0,35€/email supp.",
      payment_link: "https://google.com/",
      object: "item",
      resource_version: 1599817250235,
      status: "active",
      type: "plan",
      updated_at: 1599817250,
    },
    {
      external_name: "Advanced",
      applicable_items: [
        {
          id: "day-pass",
        },
      ],
      enabled_for_checkout: true,
      enabled_in_portal: true,
      id: "advanced",
      is_giftable: false,
      is_shippable: false,
      item_applicability: "restricted",
      name: "Advanced",
      amount_per_mail: "+ 0,30€/email supp.",
      payment_link: "https://google.com/",
      object: "item",
      resource_version: 1599817250235,
      status: "active",
      type: "plan",
      updated_at: 1599817250,
    },
    {
      external_name: "Pro",
      applicable_items: [
        {
          id: "day-pass",
        },
      ],
      enabled_for_checkout: true,
      enabled_in_portal: true,
      id: "pro",
      is_giftable: false,
      is_shippable: false,
      item_applicability: "restricted",
      name: "Pro",
      amount_per_mail: "+ 0,25€/email supp.",
      payment_link: "https://google.com/",
      object: "item",
      resource_version: 1599817250235,
      status: "active",
      type: "plan",
      updated_at: 1599817250,
    },
  ];
}
async function getUserPrefs() {
  const account = new Account(appwriteClient);
  const promise = account.getPrefs();
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      // console.log(error);
      return undefined;
    }
  );
}
/*
async function checkSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

  const promise = account.getSession('current');

  promise.then(
    function (response) {
      // console.log('Success on session check');
      // console.log(response);
      return response;
    },
    function (error) {
      // console.log('error on session check', error);
      return null;
    }
  );
}
*/

async function getUserCompanyInfos(userId) {
  // console.log({ userId });

  const client = new Client();
  const databases = new Databases(client, "default");

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
  const promise = databases.getDocument("default", "client_infos", userId);
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      // console.log("Error on getUserCompanyInfos", error, error.code);
      return null;
    }
  );
}

export const appwriteDatabase = new Databases(appwriteClient, "default");

export {
  APIClient,
  setAuthorization,
  getLoggedinUser,
  createEmailAccount,
  updateUserPrefs,
  logoutUserSession,
  createEmailSession,
  createEmailSessionPromise,
  isUserAuthenticated,
  getUser,
  createLog,
  getEmployeeLink,
  getItemFromChargeBee,
  fetchMailsList,
  getUserPrefs,
  getCompanyInfos,
  getUserCompanyInfos,
  fetchFoldersList,
  createFeedBack,
  shopifyOauth,
  shopifyOauthCallback,
};
