/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Client, Databases, Query } from "appwrite";

import { Toast, ToastHeader, ToastBody, Spinner, Container, Row, Col, Card, InputGroup, InputGroupText, Input } from "reactstrap";
import { withTranslation } from "react-i18next";

const Log = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  const [log, setLog] = useState([]);
  const [loadingIcon, setloadingIcon] = useState({
    loadCDDDdata: true,
    showDeleteIcon: false,
    loadCDDdataRemove: false,
  });
  const [returnID, setReturnID] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [toast, settoast] = useState(false);
  const [deleteCount, setDeleteCount] = useState({
    countElement: "",
    totalElement: "",
  });
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRowsCustom, setTotalRowsCustom] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fecthLogs(userInfo.$id, 1);
  }, []);

  const convertHourlyToDate = (timestamp) => {
    if (timestamp) {
      return new Date(timestamp).toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }

    return "-";
  };

  const column = [
    {
      name: <span className="font-weight-bold fs-13">Evènement </span>,
      selector: (row) => <td> {props.t(row.event)}</td>,
    },
    {
      name: <span className="font-weight-bold fs-13">Auteur</span>,
      selector: (row) => <td> {row.author_id === userInfo.$id ? "Vous" : row.author_id}</td>,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => <td> {convertHourlyToDate(row.$createdAt)}</td>,

      sortable: true,
    },
  ];

  async function getCompanyLogs(userId, limit, cursor, cursorBefore) {
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const queries = [Query.equal("company_id", userId), Query.limit(parseInt(limit)), Query.orderDesc("$createdAt")];
    if (cursor) {
      if (cursorBefore) {
        queries.push(Query.cursorBefore(cursor));
      } else {
        queries.push(Query.cursorAfter(cursor));
      }
    }
    const promise = databases.listDocuments("default", "logs", queries);

    return promise.then(
      async function (response) {
        return response;
      },
      function (error) {
        console.log("Error listing company logs ", error);
        return null;
      }
    );
  }

  const handleSelectElement = async ({ selectedRows }) => {
    setloadingIcon((prev) => ({ ...prev, showDeleteIcon: false }));

    if (selectedRows.length > 0) {
      setloadingIcon((prev) => ({ ...prev, showDeleteIcon: true }));
      const id = selectedRows.map((map) => map.$id);

      setReturnID(id);
      console.log({ returnID });
    }
  };
  const removeMultipleCompany = async () => {
    setloadingIcon((prev) => ({ ...prev, loadCDDdataRemove: true }));
    setDeleteCount((prev) => ({ ...prev, totalElement: returnID.length }));
    toggleToast();
    for (let index = 0; index < returnID.length; index++) {
      // const element = returnID[index];
      //   const val = await deleteCompany(element);
      if (index === 1) {
        setDeleteCount((prev) => ({ ...prev, countElement: index + 1 }));
      }
    }
    // const contrats = await getClients();
    // setCompany(contrats);
    setloadingIcon((prev) => ({
      ...prev,
      loadCDDdataRemove: false,
      showDeleteIcon: false,
    }));
    handleClearRows();
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const toggleToast = () => {
    settoast(!toast);
  };

  const renderCompanyList = (logList) => {
    if (loadingIcon.loadCDDDdata) {
      return (
        <div style={{ display: "grid", placeItems: "center", height: "50vh" }}>
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" />
            <rect x="11" y="6" rx="1" width="2" height="7">
              <animateTransform attributeName="transform" type="rotate" dur="9s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </rect>
            <rect x="11" y="11" rx="1" width="2" height="9">
              <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      );
    } else if (!logList) {
      return <div>Échec de chargement</div>;
    } else if (logList) {
      return (
        <Col lg="12">
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <Row className="align-items-center justify-content-between flex-row mb-4">
              <div style={{ width: "fit-content" }}>
                <h4 className="section-title mb-0" style={{ fontSize: "14px" }}>
                  {props.t("Logs")}
                </h4>
              </div>
              <InputGroup
                className="ms-auto"
                style={{
                  width: "fit-content",
                  backgroundColor: "white",
                  borderRadius: "13.511px",
                }}>
                <InputGroupText
                  onClick={handleInputSearch}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21 21L18.4953 18.4953C18.1033 18.1033 17.9073 17.9073 17.7092 17.8311C17.5121 17.7553 17.3684 17.7425 17.161 17.7825C16.9527 17.8226 16.6814 18.0117 16.1387 18.3897C14.6817 19.4048 12.9104 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.2414 19.7487 13.4241 19.2941 14.5"
                      stroke="#5D5FEF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </InputGroupText>
                <Input
                  placeholder={props.t("Recherche") + "..."}
                  style={{
                    font: "normal 400 15.2px/normal Poppins, sans-serif",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#737791",
                  }}
                  onChange={handleSearchInput}
                  onKeyDown={handleKeyDown}
                />
                <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}>
                  <button className="btn" style={{ padding: "0", border: "none" }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13 21.0001H21M9.99999 14.9996H6.99999M21 14.9996H14M9 9.00006H21M13 2.99982H3M21 2.99982H17"
                        stroke="#5D5FEF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </InputGroupText>
              </InputGroup>
            </Row>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4">Liste des Logs</h4>
                  {loadingIcon.showDeleteIcon && !loadingIcon.loadCDDdataRemove && (
                    <i className="fas fa-trash-alt font-size-24" style={{ cursor: "pointer", color: "#fd3b5e" }} onClick={removeMultipleCompany}></i>
                  )}
                  {loadingIcon.loadCDDdataRemove && (
                    <Spinner color="primary" size="md">
                      Loading...
                    </Spinner>
                  )}
                </div>

                <div className="table-responsive" style={{ minHeight: "50vh" }}>
                  <DataTable
                    columns={column}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    // onRowClicked={(e) => handleEditClick(e.$id)}
                    data={logList}
                    selectableRows
                    onSelectedRowsChange={handleSelectElement}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsCustom}
                    progressPending={loading}
                    onChangePage={handlePageChangeCustom}
                    noDataComponent="Il n'y a aucun enregistrement à afficher."
                    clearSelectedRows={toggledClearRows}
                    paginationComponentOptions={{
                      noRowsPerPage: false,
                      rangeSeparatorText: "sur",
                      rowsPerPageText: "Nbre par page",
                    }}
                  />
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    }
  };

  const handleSearchInput = async (event) => {
    let val = event.target.value;
    console.log({ val });
    setSearchTerm(val);
    if (val.length === 0) {
      const search = await searchCompanyLogs(undefined, userInfo.$id);
      console.log("search is", search);
      setLog(search);
    }
  };

  const handleInputSearch = async () => {
    try {
      const id = filteredUsers(searchTerm);
      console.log({ id });

      const search = await searchCompanyLogs(id, userInfo.$id);

      setLog(search);
    } catch (error) {
      console.log({ error });
    }
  };

  const handlePageChangeCustom = (page) => {
    fecthLogs(userInfo.$id, page);
  };

  const fecthLogs = async (userId, page, limit) => {
    try {
      setLoading(true);
      const cursorBefore = page < currentPage;
      const cursor = log.length > 0 ? (cursorBefore ? log[0].$id : log[log.length - 1].$id) : undefined;
      const response = await getCompanyLogs(userId, limit ?? perPage, cursor, cursorBefore);

      setTotalRowsCustom(response.total);

      setCurrentPage(page);
      //  const logs = await getCompanyLogs(userId);
      const employeeData = await getCompanyEmployeesData(userId);
      const agentList = await getUserCompanyInfos(userId);
      const employeesList = employeeData[0].employees ?? [];
      const ownerData = employeeData[0].owner;
      let owner;
      if (ownerData) {
        owner = JSON.parse(ownerData);
      }

      const employees = employeesList.map((element) => JSON.parse(element));
      const agentData = agentList.agents.map((agent) => JSON.parse(agent));
      setLog(response.documents);
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
      const newAgentData = agentData.map((agent) => ({
        id: agent.id,
        name: agent.agent_name,
      }));
      const newEmployesData = employees.map((employe) => ({
        id: employe.id,
        name: `${employe.first_name} ${employe.last_name}`,
      }));
      const ownerForm = {
        id: owner.id,
        name: `${owner.first_name} ${owner.last_name}`,
      };
      const mergeData = [...newAgentData, ...newEmployesData, ownerForm];
      setSearchData(mergeData);
      setLoading(false);
    } catch (error) {
      setloadingIcon((prev) => ({ ...prev, showDeleteIcon: true }));
      setLoading(false);
      // setTaostNotification((prev) => ({
      //   ...prev,
      //   fail: true,
      // }));
      // setTimeout(() => {
      //   setTaostNotification((prev) => ({
      //     ...prev,
      //     fail: false,
      //   }));
      // }, 3000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchTerm.length > 0) {
        handleInputSearch();
      }
    }
  };

  const filteredUsers = (input) => {
    const searchResult = searchData.filter((user) => user.name.toLowerCase().includes(input.toLowerCase()));
    return searchResult.at(0).id;
  };

  async function searchCompanyLogs(THE_TEXT_TO_SEARCH, userID) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    console.log({ THE_TEXT_TO_SEARCH });
    console.log({ userID });
    let queries = [Query.equal("company_id", userID)];
    if (THE_TEXT_TO_SEARCH) {
      queries.push(Query.search("author_id", THE_TEXT_TO_SEARCH));
    }
    const promise = databases.listDocuments("default", "logs", queries);

    return promise.then(
      async function (response) {
        console.log({ response });
        return response.documents;
      },
      function (error) {
        console.log("Error listing company logs ", error);
        console.log(error);
        return null;
      }
    );
  }

  async function getCompanyEmployeesData(userId) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = databases.listDocuments("default", "employee", [Query.equal("company_id", userId)]);

    return promise.then(
      async function (response) {
        return response.documents;
      },
      function (error) {
        console.log("Error listing company employees ", error);
        return null;
      }
    );
  }

  async function getUserCompanyInfos(userId) {
    console.log({ userId });

    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    const promise = databases.getDocument("default", "client_infos", userId);
    return promise.then(
      async function (response) {
        return response;
      },
      function (error) {
        console.log("Error on getUserCompanyInfos", error, error.code);
        return null;
      }
    );
  }

  return (
    <React.Fragment>
      <Container fluid={true} style={{ maxWidth: "60%" }}>
        {renderCompanyList(log)}
        <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: "1005" }}>
          <Toast isOpen={toast}>
            <ToastHeader toggle={toggleToast}>
              <div
                className=""
                style={{
                  marginTop: "3vh",
                  color: "#151d48",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "90%",
                }}>
                MAIA-ADMIN
              </div>
            </ToastHeader>
            <ToastBody color="primary">
              {`${deleteCount.countElement} supprimé${deleteCount.countElement > 1 ? "s" : ""} sur ${deleteCount.totalElement}`}
            </ToastBody>
          </Toast>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Log);
