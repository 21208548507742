import React from "react";

function ShoppingCartOutlined({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 4.00006H18.6991C20.1568 4.00006 20.8856 4.00006 21.3721 4.30415C21.7981 4.57046 22.1083 4.98737 22.241 5.47196C22.3924 6.02528 22.183 6.72338 21.7641 8.11957L21.0262 10.5793C20.658 11.8067 20.4739 12.4204 20.1095 12.8754C19.7879 13.277 19.3684 13.5891 18.8913 13.7818C18.3508 14.0001 17.7101 14.0001 16.4287 14.0001H10.8185C9.45509 14.0001 8.77341 14.0001 8.20945 13.7595C7.71196 13.5473 7.2811 13.2045 6.96245 12.7675C6.60123 12.2721 6.44795 11.6078 6.14138 10.2794L4.56215 3.43604C4.53794 3.33115 4.52584 3.2787 4.51357 3.23234C4.17437 1.95087 3.03433 1.04394 1.7094 1.00154C1.66147 1 1.60765 1 1.5 1V1L1 1.00006M14 14H6C4.89543 14 4 14.8954 4 16V16C4 17.1046 4.89543 18 6 18H21M17 18C18.1046 18 19 18.8954 19 20C19 21.1046 18.1046 22 17 22C16.2597 22 15.6134 21.5978 15.2676 21M11 20C11 21.1046 10.1046 22 9 22C8.25972 22 7.61337 21.5978 7.26756 21C7.09739 20.7058 7 20.3643 7 20C7 18.8954 7.89543 18 9 18C10.1046 18 11 18.8954 11 20Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ShoppingCartOutlined;
