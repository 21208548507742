import React, { useEffect, useState } from "react";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import checkImgSrc from "../../assets/images/sucesscheck.png";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Account, Client } from "appwrite";

const VerifyAccount = () => {
  document.title = "Verify Account | IA - Smart Assistant";
  const { search } = useLocation();

  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);
  const onVerificationLoading = (val) => {
    setLoading(val);
  };
  const updateAccountVerification = async () => {
    const client = new Client().setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const account = new Account(client);

    const urlParams = new URLSearchParams(search);
    const secret = urlParams.get("secret");
    const userId = urlParams.get("userId");

    const promise = account.updateVerification(userId, secret);

    await promise.then(
      function (response) {
        onVerificationLoading(false);
        setMessage({ type: "success", alert: "" });
        console.log(response);
      },
      function (error) {
        onVerificationLoading(false);
        setMessage({ type: "error", alert: "Echec lors de la vérification." });
        console.log(error);
      },
    );
  };
  useEffect(() => {
    console.log(search);
    if (search) {
      onVerificationLoading(true);
      updateAccountVerification();
    } else {
      setMessage({ type: "error", alert: "Echec lors de la vérification." });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <div
                            className=""
                            style={{
                              marginTop: "3vh",
                              color: "#151d48",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "54px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "140%",
                            }}>
                            FINTINOS
                          </div>
                        </Link>
                      </div>
                      <h4 className="font-size-18 text-muted mt-2 text-center">Vérification de votre compte</h4>
                      <form className="form-horizontal" action="#">
                        <Row>
                          <Col md={12}>
                            {!loading && message.type === "succes" && (
                              <div className="alert alert-success text-center">Félicitations ! Votre compte est maintenant vérifé.</div>
                            )}
                            {!loading && message.type === "error" && <div className="alert alert-danger text-center">{message.alert}</div>}
                          </Col>
                          <Col className="d-flex justify-content-center">
                            <img style={{ display: loading || message.type === "error" ? "none" : "block" }} src={checkImgSrc} alt="" />
                            {loading && <span className="spinner-border spinner-border-xxl me-2 mt-4" role="status" aria-hidden="true"></span>}
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="simple-text font-primary">
                    Vous n'avez pas de compte ?{" "}
                    <Link to="/register" className="fw-medium text-primary simple-text">
                      {" "}
                      Inscrivez-vous{" "}
                    </Link>{" "}
                  </p>
                  <p className="simple-text font-primary">© {new Date().getFullYear()} iAssistant. By SemeCloud</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifyAccount;
