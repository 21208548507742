import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Client, Account, Databases, Query } from "appwrite";
import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { indexOf } from "lodash";
import getFrenchTag from "../../helpers/local_text_helper";
import { Legend, RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";
import contact_reason from "../../assets/images/contact-reason.png";
import logo_maaia from "../../assets/images/maaia-logo.png";

const values = [
  "product_info",
  "product_availability",
  "product_advice",
  "product_problems",
  "price",
  "promotions",
  "order_info",
  "order_process",
  "payments",
  "refunds",
  "disputes",
  "billing",
  "delivery",
  "returns",
  "customer_account",
  "password",
  "security",
  "privacy",
  "customer_data",
  "after_sales",
  "technical_problems",
  "website_feedback",
  "special_programs",
  "affiliation",
  "customer_loyalty",
  "feedback",
  "complaints",
  "company_information",
  "ethics_social_responsibility",
  "subscription_management",
  "store_services",
  "influencers",
  "content_creators",
  "spam",
  "other",
];

const stats = {
  "1 semaine": {
    delivery: 10,
    returns: 20,
    product_info: 30,
    order_info: 40,
  },
  "1 mois": {
    delivery: 15,
    returns: 25,
    product_info: 30,
    order_info: 30,
  },
  "3 mois": {
    delivery: 5,
    returns: 15,
    product_info: 60,
    order_info: 20,
  },
};

const stringToColour = (str) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};
let colorMap;
values.forEach((value) => {
  if (colorMap) {
    colorMap[value] = stringToColour(value);
  } else {
    colorMap = {};
    colorMap[value] = stringToColour(value);
  }
});

const TagsList = (props) => {
  const theMessages = props.messages;
  const period = props.period;
  console.log(" theMessages is: ", theMessages);

  // let RadialChartData = null;
  const navigate = useNavigate();
  const contactReasonsMap = {};

  const [contactReasonsValueList, setcontactReasonsValueList] = useState();
  const [contactReasonsObjectList, setContactReasonsObjectList] = useState(undefined);
  const [contactReasonsKeyList, setcontactReasonsKeyList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDateChange = (period) => {
    contactReasonsMap["order_info"] = { val: stats[period].order_info, total: 100 };
    contactReasonsMap["product_info"] = { val: stats[period].product_info, total: 100 };
    contactReasonsMap["returns"] = { val: stats[period].returns, total: 100 };
    contactReasonsMap["delivery"] = { val: stats[period].delivery, total: 100 };

    console.log("contactReasonsMap is: ", contactReasonsMap);
    console.log("contactReasonsMap.values() is: ", Object.values(contactReasonsMap));
    console.log("contactReasonsMap.keys() is: ", Object.keys(contactReasonsMap));
    setcontactReasonsValueList(Object.values(contactReasonsMap));
    setcontactReasonsKeyList(Object.keys(contactReasonsMap));
    setLoading(false);

    const values_data = Object.values(contactReasonsMap);
    values_data.sort((a, b) => b - a);
    const top10_values_data_Elements = values_data.slice(0, 5).map((element) => ((element.val * 100) / element.total).toFixed(0));

    console.log("top10_values_data_Elements is: ", top10_values_data_Elements);

    const keys_data = Object.keys(contactReasonsMap);
    keys_data.sort((a, b) => {
      const valA = contactReasonsMap[a].val;
      const valB = contactReasonsMap[b].val;
      return valB - valA;
    });
    const top10_keys_data_Elements = keys_data.slice(0, 5);

    console.log("top10_keys_data_Elements is: ", top10_keys_data_Elements);

    const RadialChartData = {
      series: top10_values_data_Elements,
      options: {
        stroke: {
          width: 0.1,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "60%",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
              },
              value: {
                fontSize: "16px",
              },
            },
          },
        },

        labels: top10_keys_data_Elements.map((element) => getFrenchTag(element)),
        colors: top10_keys_data_Elements.map((element) => stringToColour(element)),
      },
    };
    console.log("RadialChartData is: ", RadialChartData);
    setContactReasonsObjectList(RadialChartData);
  };

  useEffect(() => {
    handleDateChange(period);
  }, [period]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          {loading ? (
            // Show this when loading is false
            <div>Loading</div>
          ) : (
            <>
              <div style={{ width: "100%", height: "300px" }}>
                {contactReasonsObjectList["series"].length > 0 && contactReasonsObjectList["series"].length ? (
                  <ResponsiveContainer>
                    <ReactApexChart
                      options={
                        contactReasonsObjectList
                          ? contactReasonsObjectList["options"]
                          : {
                              stroke: {
                                width: 0.1,
                              },
                              plotOptions: {
                                radialBar: {
                                  hollow: {
                                    margin: 10,
                                    size: "70%",
                                  },
                                  dataLabels: {
                                    name: {
                                      fontSize: "22px",
                                    },
                                    value: {
                                      fontSize: "16px",
                                    },
                                  },
                                },
                              },

                              labels: ["Autre"],
                              colors: [stringToColour("product_info")],
                            }
                      }
                      series={contactReasonsObjectList ? contactReasonsObjectList["series"] : [0]}
                      type="radialBar"
                      height="330"
                      className="apex-charts"
                    />
                  </ResponsiveContainer>
                ) : (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                      backgroundImage: "url(" + contact_reason + ")",
                      height: "100%",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="font-primary font-size-22 color-black mt-5">En attente de données</div>
                    <img
                      src={logo_maaia}
                      height={40}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </Col>
        <Col
          xl={12}
          className=""
        >
          {!contactReasonsKeyList ? null : (
            <div className="d-flex flex-wrap justify-content-between">
              {contactReasonsKeyList.slice(0, 5).map((contact_reason, index) => (
                <React.Fragment key={index}>
                  <div
                    style={{ display: "flex" }}
                    key={index}
                    className="align-items-center  mb-2"
                  >
                    <div
                      className=""
                      style={{
                        backgroundColor: stringToColour(contact_reason),
                        borderRadius: "2.269px",
                        width: "2.269px",
                        height: "12.855px",
                        borderRadius: "3.781px",
                        marginRight: "5px",
                      }}
                    ></div>
                    <div
                      xl={11}
                      className="legend-text simple-text font-primary"
                    >
                      {getFrenchTag(contact_reason)}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </Col>
      </Row>

      {/* </Col> */}
    </React.Fragment>
  );
};
export default TagsList;
