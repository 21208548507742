import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import progression_bar from "../../assets/images/progression-bar.png";
import logo_maaia from "../../assets/images/maaia-logo.png";

const stats = {
  "1 semaine": {
    labels: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    datasets: [
      {
        label: "Taux de progression (%)",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#563BFF"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#563BFF",
        data: [80, 40, 10, 70, 20, 20, 14],
      },
    ],
  },
  "1 mois": {
    labels: ["Semaine 1", "Semaine 2", "Semaine 3", "Semaine 4"],
    datasets: [
      {
        label: "Taux de progression (%)",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#563BFF"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#563BFF",
        data: [80, 40, 10, 70],
      },
    ],
  },
  "3 mois": {
    labels: [
      "Semaine 1",
      "Semaine 2",
      "Semaine 3",
      "Semaine 4",
      "Semaine 5",
      "Semaine 6",
      "Semaine 7",
      "Semaine 8",
      "Semaine 9",
      "Semaine 10",
      "Semaine 11",
      "Semaine 12",
    ],
    datasets: [
      {
        label: "Taux de progression (%)",
        lineTension: 0.2,
        backgroundColor: "rgba(235, 239, 242, 0)",
        borderColor: ["#563BFF"],
        borderWidth: 3,
        fill: false,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#563BFF",
        data: [80, 40, 10, 70, 20, 20, 14, 17, 50, 60, 30, 40],
      },
    ],
  },
};
const ProgressionBar = (props) => {
  const period = props.period;
  const data = stats[period];
  // const [data, setData] = useState(null);
  var option = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 50,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };
  return data ? (
    <div
      style={{
        position: "relative",
        display: "grid",
        gridTemplateRows: "minmax(0,3fr)",
        height: "86%",
      }}
    >
      <Line
        data={data}
        options={option}
      />
    </div>
  ) : (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        backgroundImage: "url(" + progression_bar + ")",
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="font-primary font-size-22 color-black mt-5">En attente de données</div>
      <img
        src={logo_maaia}
        height={40}
      />
    </div>
  );
};

export default ProgressionBar;
