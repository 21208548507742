import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Client, Account } from "appwrite";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner, InputGroup, InputGroupText } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const Login2FA = (props) => {
  document.title = "Login | IA - Smart Assistant";
  const [requestIsLoading, setRequestIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onRequestIsLoading = (value) => {
    setRequestIsLoading(value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Veuillez entrer votre Email"),
      password: Yup.string().required("Veuillez entrer votre mot de passe"),
    }),
    onSubmit: (values) => {
      //  dispatch(loginUser(values, props.router.navigate));

      onRequestIsLoading(true);

      const client = new Client();

      const account = new Account(client);

      client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
      const promise = account.createEmailSession(validation.values.email, validation.values.password);

      promise.then(
        function (response) {
          console.log("Success on session create at login page");
          console.log(response);
          onRequestIsLoading(false);
          localStorage.setItem("authUser", JSON.stringify(response));
          props.router.navigate("/dashboard", {
            replace: false,
          });
        },
        function (error) {
          console.log("error on session check", error);
          onRequestIsLoading(false);
          //   form.error_text = error;
        },
      );
    },
  });

  const { error } = useSelector((state) => ({
    error: state.login.error,
  }));

  const [selectedOption, setSelectedOption] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);

  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const svgs = {
    plus_icon: (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.01667 13.7836C6.62474 13.7836 6.32189 13.6767 6.10811 13.4629C5.89433 13.2313 5.78744 12.9195 5.78744 12.5276V8.14517H1.48517C1.11106 8.14517 0.817111 8.04719 0.603333 7.85123C0.389556 7.63745 0.282667 7.34351 0.282667 6.96939C0.282667 6.59528 0.389556 6.31025 0.603333 6.11428C0.817111 5.9005 1.11106 5.79362 1.48517 5.79362H5.78744V1.54478C5.78744 1.15286 5.89433 0.850005 6.10811 0.636227C6.32189 0.422449 6.63365 0.315561 7.04339 0.315561C7.43532 0.315561 7.72926 0.422449 7.92522 0.636227C8.139 0.850005 8.24589 1.15286 8.24589 1.54478V5.79362H12.5482C12.9401 5.79362 13.234 5.9005 13.43 6.11428C13.6438 6.31025 13.7507 6.59528 13.7507 6.96939C13.7507 7.34351 13.6438 7.63745 13.43 7.85123C13.234 8.04719 12.9401 8.14517 12.5482 8.14517H8.24589V12.5276C8.24589 12.9195 8.139 13.2313 7.92522 13.4629C7.72926 13.6767 7.42641 13.7836 7.01667 13.7836Z"
          fill="currentColor"
        />
      </svg>
    ),
    plant: (
      <svg
        style={{
          marginTop: "auto",
          alignSelf: "end",
          marginRight: "-28px",
        }}
        width="222"
        height="328"
        viewBox="0 0 222 328"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M114.876 241.477C114.661 241.477 114.661 241.477 114.446 241.477C113.8 241.262 113.584 240.619 113.799 239.974C114.015 239.33 132.319 181.769 133.827 168.453C133.827 167.808 134.472 167.379 135.118 167.379C135.764 167.379 136.195 168.023 136.195 168.668C134.688 182.199 116.814 238.256 115.953 240.618C115.738 241.262 115.307 241.477 114.876 241.477Z"
          fill="#59827A"
        />
        <path
          d="M114.878 241.478C114.232 241.478 113.801 241.048 113.801 240.404C113.801 239.33 112.724 142.036 115.955 114.974C115.955 114.33 116.601 113.9 117.247 113.9C117.893 113.9 118.324 114.545 118.324 115.189C115.093 142.036 116.17 239.33 116.17 240.404C115.955 241.048 115.524 241.478 114.878 241.478Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M117.683 269.083C117.683 269.083 115.793 252.008 110.542 234.933C107.181 223.111 102.35 211.948 96.2594 206.256C95.4193 205.599 94.1592 206.036 93.7391 206.912C93.3191 208.007 93.3187 209.101 94.3688 210.196C100.04 215.231 104.451 225.52 107.181 236.465C112.222 252.665 114.112 269.741 114.112 269.741C114.112 270.835 115.162 271.93 116.422 271.711C117.472 271.711 117.893 270.616 117.683 269.083Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M119.104 121.379C116.338 121.844 112.949 117.357 112.038 115.363C106.22 102.819 105.803 92.9313 104.34 78.862C104.324 77.9777 110.23 76.0664 110.23 76.0664C110.23 76.0664 104.551 71.4815 104.468 69.5038C104.36 63.3135 107.223 51.7225 109.032 46.6103C109.017 45.7259 112.21 36.2731 116.807 29.1361C121.97 20.9703 128.731 15.0723 132.085 12.0187C142.766 2.03829 175.012 -2.41862 174.194 0.89323C172.416 7.77412 154.229 25.614 149.066 33.7798C133.278 57.9072 136.962 56.9923 126.275 91.6211C124.57 97.1514 119.104 121.379 119.104 121.379Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M151.424 34.7706C131.744 63.8627 119.326 123.577 118.661 121.744C113.176 108.891 110.865 93.9187 110.225 79.8638C109.754 69.7649 112.084 58.526 115.185 49.5391C115.981 47.7869 124.305 50.6069 124.305 50.6069C124.305 50.6069 118.417 45.1839 118.826 43.5279C122.691 31.02 136.792 16.6376 144.786 9.10133C155.32 -0.620075 178.532 -0.828961 176.237 1.5172C166.814 10.7409 160.151 21.9443 151.424 34.7706Z"
          fill="#3D5853"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M128.592 215.595C129.684 214.652 138.527 171.149 149.06 149.839C150.04 147.594 156.311 148.661 156.311 148.661C156.311 148.661 151.009 143.577 152.042 141.54C154.156 136.788 165.049 123.815 177.374 114.692C190.998 104.574 202.053 101.762 205.758 101.485C225.699 100.406 221.13 105.117 221.13 105.117C221.13 105.117 198.602 129.034 191.71 138.549C181.084 152.563 171.234 165.271 159.822 178.819C154.948 184.938 128.592 215.595 128.592 215.595Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M109.834 143.584C109.182 148.366 108.126 161.842 106.582 163.743C105.456 165.547 91.1203 148.574 89.7311 147.352C83.5746 140.837 74.8538 134.695 68.8581 127.922C57.7536 114.392 50.743 99.4743 46.7778 83.4109C46.2901 81.3184 51.9085 76.0492 51.9085 76.0492C51.9085 76.0492 44.5342 73.7856 44.0953 71.9024C42.4315 61.9224 41.0897 51.4267 40.6345 40.9466C40.5026 39.4334 47.3778 33.8736 47.3778 33.8736C47.3778 33.8736 39.9402 32.2861 39.6621 30.1452C38.739 19.5529 38.7323 7.205 46.6321 6.03925C54.1616 5.17969 68.0406 27.7708 75.1329 34.5103C75.4888 35.0896 73.9065 42.5125 73.9065 42.5125C73.9065 42.5125 82.1618 41.9262 82.2593 42.3447C91.5501 51.8836 92.8675 57.5331 97.8895 69.6031C98.6355 71.8563 92.1012 78.8812 92.1012 78.8812C92.1012 78.8812 94.6994 79.6035 97.1021 79.4887C99.3439 79.6318 101.069 79.4533 101.361 80.7088C102.776 86.7769 104.971 96.1929 105.854 102.825C107.162 112.226 112.809 130.767 109.834 143.584Z"
          fill="#3D5853"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M127.892 215.326C129.071 214.494 150.397 163.423 157.277 145.854C161.699 134.964 172.342 124.864 184.375 116.726C185.766 115.862 194.523 120.395 194.523 120.395C194.523 120.395 192.577 111.998 193.756 111.166C201.81 106.895 208.424 104.582 215.774 102.809C227.806 100.323 218.799 112.564 218.799 112.564C218.799 112.564 206.286 135.991 198.46 144.574C186.507 157.482 175.455 169.166 162.766 181.534C156.953 187.637 127.892 215.326 127.892 215.326Z"
          fill="#3D5853"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M102.43 222.189C101.138 221.545 97.0486 171.502 86.7121 155.608C80.6825 145.729 63.4541 131.554 50.3182 125.97C48.8108 125.325 41.0567 131.339 41.0567 131.339C41.0567 131.339 41.2754 122.747 40.1987 122.103C31.5849 119.311 11.3392 119.74 0.141365 129.405C-1.1507 130.694 7.6784 134.561 7.6784 134.561C7.6784 134.561 32.2293 148.95 41.7044 156.252C55.9171 166.776 65.1752 175.153 78.7419 188.684C84.3408 194.053 102.43 222.189 102.43 222.189Z"
          fill="#3D5853"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M134.953 169.735C132.148 169.785 124.725 158.631 124.348 156.662C120.637 144.378 121.825 135.262 122.657 122.173C122.785 121.361 128.92 120.415 128.92 120.415C128.92 120.415 124.059 115.441 124.297 113.618C125.191 107.932 129.892 97.703 132.504 93.266C132.631 92.4538 137.311 84.2289 143.001 78.3169C149.416 71.5396 157.041 67.0575 160.844 64.7171C172.997 57.0293 205.534 57.3404 204.191 60.2629C201.324 66.3244 180.494 80.1897 174.079 86.9671C154.599 106.919 158.381 106.583 142.235 136.851C139.46 141.703 134.953 169.735 134.953 169.735Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M136.709 172.803C133.705 172.87 130.222 165.37 129.646 163.419C126.315 150.901 126.46 136.872 128.106 123.912C129.277 114.597 133.398 104.62 137.915 96.8104C138.984 95.3137 146.742 99.029 146.742 99.029C146.742 99.029 141.81 93.2604 142.482 91.7991C148.323 80.8681 164.57 69.6126 173.68 63.7971C185.651 56.3256 207.292 58.2063 205.949 61.1289C203.064 66.9917 187.034 78.4277 176.542 88.9712C152.424 113.14 151.737 125.615 146.166 137.323C143.789 142.139 136.709 172.803 136.709 172.803Z"
          fill="#3D5853"
        />
        <path
          d="M114.019 265.318C113.803 265.318 113.803 265.318 113.588 265.318C112.942 265.103 112.727 264.458 112.942 263.814C116.603 254.578 114.234 175.326 87.3158 107.886C83.8703 99.2953 80.6405 90.9188 77.4103 82.972C68.3659 60.2056 59.9672 38.7275 58.2444 36.365C58.0291 36.1502 57.8138 35.9358 57.8138 35.721C57.5985 35.0767 58.029 34.4322 58.675 34.4322C60.3978 34.0026 60.6135 34.0022 79.5638 82.3271C82.5786 90.2738 86.0238 98.6503 89.4693 107.241C116.387 175.111 118.756 255.437 115.096 264.888C114.88 264.888 114.449 265.318 114.019 265.318Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M178.464 199.708C178.464 199.708 182.673 203.693 183.033 204.474C184.385 207.402 187.089 213.259 186.756 217.67C186.514 219.201 186.901 228.25 185.443 225.606C182.093 218.863 174.877 215.548 172.561 215.664C167.538 216.075 160.332 221.509 155.94 223.287C154.766 223.825 152.691 225.487 151.548 225.064C151.353 225.154 151.473 224.389 151.864 224.21C152.677 222.891 153.114 221.271 154.213 220.057C159.059 213.103 160.368 211.32 166.611 205.854C170.689 201.855 173.336 200.404 178.464 199.708Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M118.349 248.947C119.276 248.228 113.331 239.066 118.794 223.535C122.023 214.689 130.442 208.558 140.166 201.81C141.266 201.056 148.413 204.629 148.413 204.629C148.413 204.629 146.867 197.803 147.623 197.118C151.025 195.372 163.67 193.394 171.436 195.595C179.409 197.934 182.811 204.213 182.811 204.213C182.811 204.213 177.621 199.716 165.938 207.388C158.482 212.08 155.666 231.009 149.447 238.308C139.826 249.137 118.349 248.947 118.349 248.947Z"
          fill="#3D5853"
        />
        <path
          d="M117.068 284.886C116.587 284.87 116.211 284.569 116.031 284.179C111.253 270.751 112.569 247.43 126.938 225.697C136.146 211.772 169.911 198.182 171.28 197.554C171.867 197.285 172.528 197.692 172.709 198.083C172.979 198.668 172.573 199.328 172.182 199.507C171.791 199.687 137.83 213.366 128.833 226.721C114.87 247.795 113.389 270.245 118.182 283.192C118.452 283.778 118.046 284.438 117.459 284.707C117.264 284.796 117.264 284.797 117.068 284.886Z"
          fill="#59827A"
        />
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M101.961 328.063C101.961 328.063 79.0407 302.939 82.8985 267.178H150.751C154.609 302.939 131.689 328.063 131.689 328.063H101.961Z"
          fill="#B1A4AB"
        />
      </svg>
    ),
    resend_icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
        <g style={{ clipPath: "url(#clip0_723_776)" }}>
          <path
            d="M5.00392 10.0969C6.21419 10.0896 7.23619 9.64161 8.04793 8.75294C8.66162 8.0834 9.01126 7.28725 9.08705 6.37424C9.14818 5.65113 9.03815 4.95967 8.72764 4.3023C8.68607 4.21465 8.64451 4.127 8.63962 4.02475C8.63228 3.80075 8.75698 3.62789 8.97947 3.56459C9.17507 3.51102 9.38045 3.60354 9.48069 3.79588C9.70808 4.24387 9.86945 4.7162 9.94035 5.21532C10.1848 6.94883 9.68607 8.43643 8.41712 9.64648C7.63473 10.3939 6.68852 10.8249 5.61028 10.9564C4.19707 11.1268 2.93057 10.7543 1.83522 9.86317C0.793652 9.02076 0.17996 7.91784 0.0332605 6.57875C-0.115884 5.21532 0.236194 3.98579 1.09683 2.91452C1.78143 2.06237 2.65429 1.48535 3.7203 1.20536C3.73986 1.20049 3.75942 1.19562 3.77898 1.18831C3.80588 1.18101 3.84255 1.18344 3.845 1.14692C3.84744 1.12014 3.8181 1.10797 3.80099 1.09336C3.67629 0.988669 3.54671 0.89128 3.42446 0.781718C3.29487 0.664853 3.23619 0.51877 3.2802 0.343472C3.32666 0.158434 3.4538 0.0537416 3.63228 0.00991685C3.75942 -0.0217343 3.87923 0.0147863 3.97703 0.0926968C4.40979 0.43599 4.845 0.779284 5.27042 1.13232C5.4758 1.30275 5.50514 1.517 5.36089 1.74099C5.07727 2.18167 4.78632 2.61749 4.49292 3.05086C4.36822 3.23347 4.15062 3.29433 3.96236 3.22129C3.77165 3.14825 3.65918 2.96808 3.67629 2.7587C3.68363 2.67348 3.7203 2.60288 3.76431 2.53471C3.84744 2.40567 3.93302 2.27663 4.01615 2.14515C4.03082 2.12324 4.0626 2.09889 4.04549 2.06968C4.02348 2.03072 3.98681 2.0575 3.95747 2.06724C3.40979 2.20846 2.91101 2.45193 2.46847 2.80496C1.65429 3.45016 1.1335 4.28039 0.957466 5.30053C0.744752 6.5398 1.0406 7.6622 1.84989 8.63364C2.57605 9.50283 3.51737 9.97517 4.64451 10.092C4.66162 10.0945 4.67629 10.0945 4.69341 10.0945C4.80099 10.0993 4.90123 10.0969 5.00392 10.0969Z"
            fill="#737791"
          />
        </g>
        <defs>
          <clipPath id="clip0_723_776">
            <rect width="10" height="11" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  };
  return (
    <React.Fragment>
      {/* <div className="d-flex align-items-center justify-content-center"
          style={{zIndex:"50", inset:"0", background: 'rgba(0, 0, 0, 0.75)', position:"fixed"}}
          >
            <div className="sucess-message h-xxl-100 d-flex flex-column align-items-center justify-content-center">
              <img src={successCheck} height="130" alt=""/>
          <div style={{
            color: "var(--greys-blue-grey-900, #151D48)",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontSize: "64px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "140%",
          }}>
              Welcome Back
              </div>
          <p style={{
            color: "#898989",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "130%",
            textTransform: "capitalize",}}>In aliquam sem fringilla ut morbi. Egestas maecenas pharetra convallis posuere morbi leo. Egestas sed tempus urna et pharetra pharetra massa massa ultricies.</p>
            </div>
      </div> */}
      <div className="account-pages d-flex align-items-center" style={{ minHeight: "100vh", overflow: "hidden" }}>
        <Container fluid={true}>
          <Row className="justify-content-center m-0">
            <Col lg={12} md={12} xl={10} xxl={10}>
              <div className="text-center">
                <Link
                // to="/"
                >
                  <div
                    className=""
                    style={{
                      marginTop: "3vh",
                      color: "#151d48",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "64px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "140%",
                    }}>
                    FINTINOS
                  </div>
                </Link>
              </div>
              <Card className="auth-card">
                <CardBody className="">
                  <Row>
                    <div className="auth-card__left col-xl-6">
                      <div className="auth-card_image-div"></div>
                    </div>
                    <div style={{ position: "relative" }} className="login-card__right col-xl-6 flex-column">
                      <Form
                        className="form-horizontal col-10"
                        // onSubmit={(e) => {
                        //   e.preventDefault();
                        //   validation.handleSubmit();
                        //   return false;
                        // }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className={`${selectedOption && "slide-out-left d-none"}`}>
                              <div className="login2FA__title">two step verification</div>
                              <h2 className={`login2FA__h2 mt-2`}>How do you want to get your security code</h2>
                              <p className={`login2FA__p mt-4 mb-4`}>
                                In aliquam sem fringilla ut morbi. Egestas maecenas pharetra convallis posuere morbi leo. Egestas sed tempus urna et
                                pharetra pharetra massa massa ultricies.
                              </p>
                            </div>

                            <div className={`${selectedOption ? "slide-in-right" : "d-none"}`}>
                              <div className="login2FA__title">two step verification</div>
                              <h2 className={`login2FA__h2 mt-2`}>Enter Your {selectedOption === "email" ? "Email Adress" : "Phone Number"}</h2>
                              <p className={`login2FA__p mt-4 mb-4`}>
                                In aliquam sem fringilla ut morbi. Egestas maecenas pharetra convallis posuere morbi leo. Egestas sed tempus urna et
                                pharetra pharetra massa massa ultricies.
                              </p>
                            </div>

                            <Row>
                              <Col xl={4}>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setSelectedOption("msge");
                                  }}
                                  className={`login2FA__optionButton ${selectedOption === "msge" && "login2FA__optionButtonSelected"}  ${
                                    selectedOption && "login2FA__optionSelected"
                                  }`}>
                                  {svgs.plus_icon} Text Message
                                </button>
                              </Col>
                              <Col xl={4}>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setSelectedOption("call");
                                  }}
                                  className={`login2FA__optionButton text-xl-1 ${selectedOption === "call" && "login2FA__optionButtonSelected"} ${
                                    selectedOption && "login2FA__optionSelected"
                                  }`}>
                                  {svgs.plus_icon} Phone Call
                                </button>
                              </Col>
                              <Col xl={4}>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setSelectedOption("email");
                                  }}
                                  className={`login2FA__optionButton ${selectedOption === "email" && "login2FA__optionButtonSelected"} ${
                                    selectedOption && "login2FA__optionSelected"
                                  }`}>
                                  {svgs.plus_icon} E mail
                                </button>
                              </Col>
                            </Row>

                            <Row
                              style={{ marginTop: "40px", marginBottom: "47px" }}
                              className={`${selectedOption && !isCodeSent ? "slide-in-right" : "d-none"}`}>
                              <Col xl={12}>
                                <InputGroup className="inputCustom__container row" style={{ height: "fit-content", margin: "0" }}>
                                  <IntlTelInput
                                    containerClassName="intl-tel-input col-xxl-9 col-xl-8"
                                    inputClassName="form-control"
                                    onSelectFlag={(e) => {
                                      console.log(e);
                                    }}
                                  />
                                  <InputGroupText
                                    className="col-xxl-3 col-xl-4"
                                    style={{
                                      backgroundColor: "transparent",
                                      padding: "0",
                                      border: "none",
                                    }}>
                                    <button
                                      type="button"
                                      className="inputCustom__Button"
                                      style={{ border: "none" }}
                                      onClick={() => {
                                        setIsCodeSent(true);
                                      }}>
                                      {svgs.plus_icon} Send a code
                                    </button>
                                  </InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>

                            <Row
                              style={{ marginTop: "40px", marginBottom: "24px" }}
                              className={`${isCodeSent ? "slide-in-right" : "d-none"} d-flex justify-content-between`}>
                              <Col xl={2}>
                                <input type="text" className="login2FA__codeInput" />
                              </Col>
                              <Col xl={2}>
                                <input type="text" className="login2FA__codeInput" />
                              </Col>
                              <Col xl={2}>
                                <input type="text" className="login2FA__codeInput" />
                              </Col>
                              <Col xl={2}>
                                <input type="text" className="login2FA__codeInput" />
                              </Col>
                              <Col xl={2}>
                                <input type="text" className="login2FA__codeInput" />
                              </Col>
                              <Col xl={2}>
                                <input type="text" className="login2FA__codeInput" />
                              </Col>
                            </Row>
                            <Row className={`${isCodeSent ? "slide-in-right" : "d-none"}`}>
                              <div
                                className="d-flex align-items-center gap-2"
                                style={{
                                  color: "#737791",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                  textTransform: "capitalize",
                                }}>
                                {svgs.resend_icon} Did not receive OTP Code ? <span style={{ fontWeight: "600", color: "#563BFF" }}>Resend</span>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p
                  className=""
                  style={{
                    color: "#737791",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}>
                  Copyright © {new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login2FA);

Login2FA.propTypes = {
  history: PropTypes.object,
};
