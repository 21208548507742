import React from "react";
import { Navigate, Route } from "react-router-dom";

import { useProfile } from "../Hooks/UserHooks";
import { isUserAuthenticated } from "../helpers/api_helper";

const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();

  /*
    redirect is un-auth access protected routes via url
    */
  const user_string = localStorage.getItem("user");
  const user = JSON.parse(user_string);
  const user_auth_string = localStorage.getItem("authUser");
  if (!user_auth_string) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  } else if (user_string && user && user.prefs && !user.prefs["finished_onbording"]) {
    return <Navigate to={{ pathname: "/setup", state: { from: props.location } }} />;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
