import {
  FETCH_MAILS_REDUX,
  APPLY_MAILS_SUCCESSFULL,
  APPLY_MAILS_ERROR,
  FETCH_FOLDERS_REDUX,
  APPLY_FOLDERS_SUCCESSFULL,
  APPLY_FOLDERS_ERROR,
} from "./actionTypes";

export const fetchMailsRedux = (company_id, box, currentPage, pageSize, old_messages) => {
  return {
    type: FETCH_MAILS_REDUX,
    payload: { company_id, box, currentPage, pageSize, old_messages },
  };
};

export const applyMailsSuccessful = (response) => {
  return {
    type: APPLY_MAILS_SUCCESSFULL,
    payload: {
      data: response.data ? { mails: [...(response.old_messages ?? []), ...response.data.mails] } : { mails: [...(response.old_messages ?? [])] },
      box: response.box,
      currentPage: response.currentPage,
      totalPages: response.data.totalPages,
    },
  };
};

export const applyMailsError = (response) => {
  return {
    type: APPLY_MAILS_ERROR,
    payload: {
      error: response.message,
      box: response.box,
      currentPage: response.currentPage,
      // totalPages: response.totalPages,
    },
  };
};

export const fetchFoldersRedux = (company_id) => {
  return {
    type: FETCH_FOLDERS_REDUX,
    payload: { company_id },
  };
};

export const applyFoldersSuccessful = (response) => {
  return {
    type: APPLY_FOLDERS_SUCCESSFULL,
    payload: { data: response.data, id: response.id },
  };
};

export const applyFoldersError = (error) => {
  return {
    type: APPLY_FOLDERS_ERROR,
    payload: error,
  };
};
