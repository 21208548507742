import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { Client, Account, Databases, Query } from "appwrite";
import { Link, useNavigate } from "react-router-dom";
import getFrenchTag from "../../helpers/local_text_helper";
import Flatpickr from "react-flatpickr";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import aiIcon from "../../assets/images/icons8-bot-48.png";
import manIcon from "../../assets/images/icons8-homme-48.png";

const TempsReponsePerformances = (props) => {
  const theMessages = props.messages;
  const [averageResponseTime, setAverageResponseTime] = useState();
  const [averageWaitingTime, setAverageWaitingTime] = useState();
  const [hoverText, setHoverText] = useState([]);

  const handleDateChange = (theData) => {
    var data = theData;
    console.log("TempsReponsePerformances is: ", data);
  };
  const calculateAverageResponseTime = (messages) => {
    let totalTimeDifference = 0;
    const convertMillisecondsToHMS = (milliseconds) => {
      let seconds = Math.floor((milliseconds / 1000) % 60);
      let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
      let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
      seconds = seconds < 0 ? seconds * -1 : seconds;
      minutes = minutes < 0 ? minutes * -1 : minutes;
      hours = hours < 0 ? hours * -1 : hours;
      return `${seconds}`;
      // return `${hours}h ${minutes}m ${seconds}s`;
    };
    // const convertMillisecondsToHMS = (milliseconds) => {
    //   const totalSeconds = milliseconds / 1000;
    //   const totalHours = totalSeconds / 3600; // 1 hour = 3600 seconds
    //   return totalHours.toFixed(1); // Keep one decimal place
    // };
    messages.forEach((message) => {
      const timeDifference = message.message_process_end_date - message.message_process_date;
      totalTimeDifference += timeDifference;
    });

    const avr = messages.length === 0 ? 0 : totalTimeDifference / messages.length;
    setAverageResponseTime(convertMillisecondsToHMS(avr));
    return avr;
  };
  const calculateSaveTime = (messages) => {
    let totalTimeDifference = 0;
    const convertMillisecondsToHMS = (milliseconds) => {
      const seconds = Math.floor((milliseconds / 1000) % 60);
      const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
      const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

      // return `${hours}h ${minutes}m ${seconds}s`;
      return `${minutes}`;
    };
    messages.forEach((message) => {
      const timeDifference = message.message_process_date - message.message_date;
      totalTimeDifference += timeDifference;
    });

    const averageResponseTime = messages.length === 0 ? 0 : totalTimeDifference / messages.length;
    console.log("calculateSaveTime is: ", convertMillisecondsToHMS(averageResponseTime));
    setAverageWaitingTime(convertMillisecondsToHMS(averageResponseTime));
    return averageResponseTime;
  };

  const handleIconMouseEnter = (value, text) => {
    setHoverText([value, text]);
  };

  const handleIconMouseLeave = () => {
    setHoverText("");
  };

  useEffect(() => {
    handleDateChange(theMessages);
    calculateAverageResponseTime(theMessages);
    calculateSaveTime(theMessages);
  }, [theMessages]);

  const stats = {
    "1 semaine": {
      average_ai_response_time: "32",
      average_human_response_time: "4",
      average_ai_waiting_time: "28",
      average_human_waiting_time: "13",
    },
    "1 mois": {
      average_ai_response_time: " 38",
      average_human_response_time: "4",
      average_ai_waiting_time: "36",
      average_human_waiting_time: "13",
    },
    "3 mois": {
      average_ai_response_time: "43",
      average_human_response_time: "4",
      average_ai_waiting_time: "49",
      average_human_waiting_time: "13",
    },
  };
  return (
    <React.Fragment>
      <Row>
        <Col
          className="d-flex flex-column mb-3"
          md={6}
        >
          <img
            className="mx-auto"
            width={48}
            src={aiIcon}
          />
        </Col>
        <Col
          className="d-flex flex-column mb-3"
          md={6}
        >
          <img
            className="mx-auto"
            width={48}
            src={manIcon}
          />
        </Col>
        <Col
          className="d-flex flex-column border-right-1 col mb-2"
          style={{
            color: "black",
            borderRight: "1.5px solid #dcdcdc",
          }}
        >
          <div className="mx-auto font-size-16 fw-bold font-primary">Temps traitement</div>
          <span className="mx-auto mb-2 font-primary">Maaia moy.</span>
          <div className="mx-auto font-size-20 font-primary">
            {stats[props.period].average_ai_response_time} <span className="font-size-16 font-primary">Secondes</span>
          </div>
          <div className="mx-auto mt-4 font-size-16 fw-bold font-primary">Délais de réponse</div>
          <span className="mx-auto mb-2 font-primary">Maaia moy.</span>
          <div className="mx-auto font-size-20 font-primary">
            {stats[props.period].average_ai_waiting_time} <span className="font-size-16 font-primary">Minutes</span>
          </div>
        </Col>
        <Col
          className="d-flex flex-column col mb-2"
          style={{
            color: "black",
          }}
        >
          <div className="mx-auto font-size-16 fw-bold font-primary ">Temps traitement</div>
          <span className="mx-auto mb-2 font-primary font-primary">Humain moy.</span>
          <div className="mx-auto font-size-20">
            {stats[props.period].average_human_response_time} <span className="font-size-16 font-primary">Minutes</span>
          </div>
          <div className="mx-auto mt-4 font-size-16 fw-bold font-primary">Délais de réponse</div>
          <span className="mx-auto mb-2 font-primary">Humain moy.</span>
          <div className="mx-auto font-size-20 font-primary">
            {stats[props.period].average_human_waiting_time} <span className="font-size-16 font-primary">{props.t("Heures")}</span>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default withTranslation()(TempsReponsePerformances);
