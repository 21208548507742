import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col, Row } from "reactstrap";
import logo_maaia from "../../assets/images/maaia-logo.png";
import activity_by_day from "../../assets/images/activity_by_day.png";

const stats = {
  "1 semaine": new Map([
    ["00h-04h", { day: "00h-04h", val: 5, width: (5 * 100) / 57, backgroundColor: "#FFA412" }],
    ["04h-8h", { day: "04h-8h", val: 10, width: (10 * 100) / 57, backgroundColor: "#FFA412" }],
    ["8h-12h", { day: "8h-12h", val: 8, width: (8 * 100) / 57, backgroundColor: "#FFA412" }],
    ["12h-16h", { day: "12h-16h", val: 15, width: (15 * 100) / 57, backgroundColor: "#FFA412" }],
    ["16h-20h", { day: "16h-20h", val: 12, width: (12 * 100) / 57, backgroundColor: "#FFA412" }],
    ["20h-00h", { day: "20h-00h", val: 7, width: (7 * 100) / 57, backgroundColor: "#FFA412" }],
  ]),
  "1 mois": new Map([
    ["00h-04h", { day: "00h-04h", val: 3, width: (3 * 100) / 40, backgroundColor: "#FFA412" }],
    ["04h-8h", { day: "04h-8h", val: 7, width: (7 * 100) / 40, backgroundColor: "#FFA412" }],
    ["8h-12h", { day: "8h-12h", val: 11, width: (11 * 100) / 40, backgroundColor: "#FFA412" }],
    ["12h-16h", { day: "12h-16h", val: 6, width: (6 * 100) / 40, backgroundColor: "#FFA412" }],
    ["16h-20h", { day: "16h-20h", val: 9, width: (9 * 100) / 40, backgroundColor: "#FFA412" }],
    ["20h-00h", { day: "20h-00h", val: 4, width: (4 * 100) / 40, backgroundColor: "#FFA412" }],
  ]),
  "3 mois": new Map([
    ["00h-04h", { day: "00h-04h", val: 2, width: (2 * 100) / 57, backgroundColor: "#FFA412" }],
    ["04h-8h", { day: "04h-8h", val: 6, width: (6 * 100) / 57, backgroundColor: "#FFA412" }],
    ["8h-12h", { day: "8h-12h", val: 9, width: (9 * 100) / 57, backgroundColor: "#FFA412" }],
    ["12h-16h", { day: "12h-16h", val: 11, width: (11 * 100) / 57, backgroundColor: "#FFA412" }],
    ["16h-20h", { day: "16h-20h", val: 19, width: (19 * 100) / 57, backgroundColor: "#FFA412" }],
    ["20h-00h", { day: "20h-00h", val: 10, width: (10 * 100) / 57, backgroundColor: "#FFA412" }],
  ]),
};
const AnalyseMessageSavHeure = (props) => {
  let barchartData = null;
  const [messageCountByDayData, setMessageCountByDayData] = useState(undefined);
  const period = props.period;

  const handleDateChange = (period) => {
    setMessageCountByDayData(stats[period]);
  };

  const getItemData = (item_key, the_statsData) => {
    return {
      day: the_statsData.get(item_key).day,
      val: the_statsData.get(item_key).val,
      width: the_statsData.get(item_key).width.toFixed(0),
      backgroundColor: "#563BFF",
    };
  };

  useEffect(() => {
    handleDateChange(period);
  }, [period]);

  return (
    <React.Fragment>
      {
        messageCountByDayData && Array.from(messageCountByDayData.keys()).length > 0 ? (
          Array.from(messageCountByDayData.keys()).map((item, index) => (
            <>
              <div
                key={index}
                className="d-flex align-items-center pb-2 mb-2 font-primary"
                style={{ borderBottom: index !== Array.from(messageCountByDayData.keys()).length - 1 ? "2px dashed #E6E7E9" : "none" }}
              >
                <div className="col-xl-2 customGraph_yAxis__value simple-text font-primary">{getItemData(item, messageCountByDayData).day}</div>
                <div className="customGraph_bar__container col-xl-9 font-primary">
                  <div
                    key={index}
                    style={{
                      backgroundColor: getItemData(item, messageCountByDayData).backgroundColor,
                      width: getItemData(item, messageCountByDayData).width + "%",
                      whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
                    }}
                    className="customGraph_bar"
                  ></div>
                </div>
                <div
                  className="col-xl-1 font-primary"
                  style={{
                    color: item.backgroundColor,
                    textAlign: "end",
                    paddingRight: "0",
                  }}
                >
                  {getItemData(item, messageCountByDayData).width + "%"}
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                backgroundImage: "url(" + activity_by_day + ")",
                height: "100%",
                width: "100%",
                backgroundSize: "100% 166%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="font-primary font-size-22 color-black mt-5">En attente de données</div>
              <img
                src={logo_maaia}
                height={40}
              />
            </div>
          </>
        )
        // newData.map((item, index) => (
        //   <>
        //     <div
        //       key={index}
        //       className="d-flex align-items-center pb-2 mb-2 font-primary"
        //       style={{ borderBottom: index !== newData.length - 1 ? "2px dashed #E6E7E9" : "none" }}>
        //       <div className="col-xxl-1 col-xl-1 customGraph_yAxis__value simple-text font-primary">{item.text}</div>
        //       <div className="customGraph_bar__container col-xxl-10 col-xl-9">
        //         <div
        //           key={index}
        //           style={{
        //             backgroundColor: item.backgroundColor,
        //             width: item.width,
        //             whiteSpace: "nowrap", // Empêche le texte de passer à la ligne
        //           }}
        //           className="customGraph_bar"></div>
        //       </div>

        //       <div
        //         className="col-xxl-1 col-xl-2 font-primary"
        //         style={{
        //           color: item.backgroundColor,
        //           textAlign: "end",
        //           paddingRight: "0",
        //         }}>
        //         {item.width}
        //       </div>
        //     </div>
        //   </>
        // ))
      }
    </React.Fragment>
  );
};

export default AnalyseMessageSavHeure;
