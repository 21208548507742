import React from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const DropDown = (props) => {
  const { children } = props;
  const [menu, setMenu] = React.useState(false);
  const handleImproveStyle = () => {
    console.log("Here is improvement style");
  };
  const handleTypeCorrection = () => {
    console.log("Here is type correction");
  };
  const handleShortcut = () => {
    console.log("Here is shortcut");
  };
  const handleSuggestAnswers = () => {
    console.log("Here is shortcut");
  };
  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="user-dropdown d-inline-block "
      >
        <DropdownToggle
          className="p-0 bg-transparent border-0"
          id="ia-dropdown"
          tag="button"
        >
          {children}
        </DropdownToggle>
        <DropdownMenu
          className="font-primary simple-text px-2"
          style={{ width: "25rem", textAlign: "start" }}
        >
          <DropdownItem
            style={{ textAlign: "start" }}
            onClick={handleImproveStyle}
          >
            <div className="d-flex justify-content-start flex-wrap gap-2 w-100">
              <i
                className="mdi mdi-compass-rose"
                style={{ color: "#b495c8", fontSize: "1.3rem" }}
              ></i>
              <span
                style={{
                  textAlign: "start",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                {props.t("ImproveStyle")}
              </span>
            </div>
          </DropdownItem>
          <DropdownItem divider />

          <DropdownItem onClick={handleTypeCorrection}>
            <div className="d-flex justify-content-start flex-wrap gap-2 w-100">
              {/* <i className="mdi mdi-check-bold" style={{ color: "#b495c8", fontSize: "1.3rem" }}></i> */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13L9 18L15 11.4545M20 6L17.25 9"
                  stroke="#b495c8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span
                style={{
                  textAlign: "start",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                {props.t("CorrectGrammar")}
              </span>
            </div>
          </DropdownItem>
          <DropdownItem divider />

          <DropdownItem onClick={handleShortcut}>
            <div className="d-flex justify-content-start flex-wrap gap-2 w-100">
              {/* <i className="mdi mdi-text-short" style={{ color: "#b495c8", fontSize: "1.3rem" }}></i> */}
              <svg
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 6H17M13 6H3M3 12H11M3 18H16"
                  stroke="#b495c8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>

              <span
                style={{
                  textAlign: "start",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                {props.t("Shorten")}
              </span>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={handleSuggestAnswers}>
            <div className="d-flex justify-content-start flex-wrap gap-2 w-100">
              <i
                className="fa fa-fw fa-robot align-middle "
                style={{ fontSize: "1.1rem", color: "#b495c8" }}
              />

              <span
                style={{
                  textAlign: "start",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                {props.t("SuggestResponse")}
              </span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(DropDown);
