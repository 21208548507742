import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col, Row } from "reactstrap";
import logo_maaia from "../../assets/images/maaia-logo.png";
import treated_mails from "../../assets/images/treated_mails.png";
const stats = {
  "1 semaine": {
    series: [
      {
        name: "Emails Traités",
        data: [
          { x: "2024-04-05", y: 22 },
          { x: "2024-04-06", y: 15 },
          { x: "2024-04-07", y: 17 },
          { x: "2024-04-08", y: 18 },
          { x: "2024-04-09", y: 12 },
          { x: "2024-04-10", y: 10 },
          { x: "2024-04-11", y: 20 },
        ],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#ff73d3"],
      xaxis: {
        type: "datetime",
        categories: ["2024-04-05", "2024-04-06", "2024-04-07", "2024-04-08", "2024-04-09", "2024-04-10", "2024-04-11"],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  },
  "1 mois": {
    series: [
      {
        name: "Emails Traités",
        data: [
          { x: "2024-04-01", y: 50 },
          { x: "2024-04-08", y: 40 },
          { x: "2024-04-15", y: 70 },
          { x: "2024-04-22", y: 65 },
          { x: "2024-04-29", y: 55 },
        ],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#ff73d3"],
      xaxis: {
        type: "datetime",
        categories: ["2024-04-01", "2024-04-08", "2024-04-15", "2024-04-22", "2024-04-29"],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  },
  "3 mois": {
    series: [
      {
        name: "Emails Traités",
        data: [
          { x: 1634546400000, y: 25 },
          { x: 1634632800000, y: 32 },
          { x: 1634719200000, y: 40 },
          { x: 1634805600000, y: 32 },
          { x: 1634892000000, y: 28 },
        ],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#ff73d3"],
      xaxis: {
        type: "datetime",
        categories: [1634546400000, 1634632800000, 1634719200000, 1634805600000, 1634892000000],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  },
};
const TreatedMail = (props) => {
  const period = props.period;
  const [formattedDataList, setformattedDataList] = useState({
    series: [
      {
        name: "Emails Traités",
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#82c2bf"],
      xaxis: {
        type: "datetime",
        categories: [],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  });

  const handleDateChange = (period) => {
    setformattedDataList(stats[period]);
  };
  useEffect(() => {
    handleDateChange(period);
  }, [period]);
  return (
    <React.Fragment>
      {formattedDataList && formattedDataList.series[0].data.length > 0 ? (
        <ReactApexChart
          options={formattedDataList.options}
          series={formattedDataList.series}
          type="area"
          height="235"
          className="apex-charts font-primary"
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + treated_mails + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "138%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        >
          <div className="font-primary font-size-22 color-black mt-5">En attente de données</div>
          <img
            src={logo_maaia}
            height={40}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TreatedMail;
